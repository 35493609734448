import React, { useContext, useState } from 'react';
import User from '../../../models/user';
import ProfileInfo from './ProfileInfo';
import Themes from './Themes';
import { AuthContext } from '../../../AuthContext';

export default function Content({ onUpdate }) {
  const context = useContext(AuthContext);
  const { user } = context.store;
  const [currentTheme, setCurrentTheme] = useState(user.theme ?? 'default');
  const updateProfile = async (updates) => {
    const dbUser = await User.updateProfile({ ...updates }, user.uid);
    dbUser && context.actions.updateUser(dbUser, dbUser.providers[0]);
    onUpdate();
  };
  const applyTheme = async (theme) => {
    if (theme === currentTheme) {
      return false;
    }
    setCurrentTheme(theme);

    const dbUser = await User.updateProfile({ theme }, user.uid);
    dbUser && context.actions.updateUser(dbUser, dbUser.providers[0]);
    onUpdate();
  };
  const handleCustomUpload = async (imageURL) => {
    if (!imageURL) {
      return;
    }

    setCurrentTheme('custom');
    const dbUser = await User.updateProfile(
      { theme: 'custom', customThemeBG: imageURL },
      user.uid,
    );
    dbUser && context.actions.updateUser(dbUser, dbUser.providers[0]);
    onUpdate();
  };

  return (
    <div className="col-span-7 h-screen border-r border-gray-300 p-4">
      <ProfileInfo user={user} updateProfile={updateProfile} />
      <Themes
        user={user}
        currentTheme={currentTheme}
        applyTheme={applyTheme}
        handleCustomUpload={handleCustomUpload}
      />
    </div>
  );
}
