import { useState } from 'react';

export default function usePaymentModal() {
  const [show, setShow] = useState(false);
  const handleOpen = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  return {
    show,
    handleOpen,
    handleClose,
  };
}
