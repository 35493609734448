import User from '../../models/user';
import * as EmailValidator from 'email-validator';
import { useState, useRef } from 'react';
import { validateUsername } from '../../utils/user';
import { getBase64, uploadPhotoToStorage } from '../../utils/storage';

export default function useSubmitUserForm(user) {
  const uploadRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [input, setInput] = useState({
    username: user?.username || '',
    displayName: user?.displayName || '',
    bio: user?.bio || '',
    profileImage: user?.profileImage || '',
    email: user?.email || '',
  });
  const [inputError, setInputError] = useState({
    username: null,
    displayName: null,
    bio: null,
    profileImage: null,
    email: null,
  });
  const handleInputUpdate = (field, value) => {
    if (field === 'username') {
      setInput({ ...input, [field]: validateUsername(value) });
    } else {
      setInput({ ...input, [field]: value });
    }
  };
  const usernameAvailabilityCheck = async () => {
    const isTaken = await User.usernameTaken(input.username);

    if (isTaken) {
      setInput({ ...input, username: '' });
      setInputError({ ...inputError, username: 'username is taken!' });
    } else {
      setInputError({ ...inputError, username: null });
    }
  };
  const handleFileUpload = async (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      return;
    }

    setUploading(true);
    const base64Data = await getBase64(selectedFile);
    const profileImageURL = await uploadPhotoToStorage(
      base64Data,
      'profile_images',
    );
    setInput({ ...input, profileImage: profileImageURL });
    setUploading(false);
  };
  const isValid = () => {
    const emailValid =
      input.email.length > 0 ? EmailValidator.validate(input.email) : true;
    return input.username.length >= 2 && emailValid;
  };

  return {
    uploadRef,
    uploading,
    input,
    inputError,
    handleInputUpdate,
    usernameAvailabilityCheck,
    handleFileUpload,
    isValid,
  };
}
