import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

export default function useChat() {
  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return false;
    }

    if (document && !isMobile) {
      let d = document;
      let s = d.createElement('script');
      s.src = 'https://client.crisp.chat/l.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);

      window.$crisp = [];
      window.CRISP_WEBSITE_ID = '4ede0c2c-aef2-4a68-8627-a8786c98bf50';
      (function () {
        d = document;
        s = d.createElement('script');
        s.src = 'https://client.crisp.chat/l.js';
        s.async = 1;
        d.getElementsByTagName('head')[0].appendChild(s);
      })();
    }
  }, []);
}
