import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { CURRENCIES } from '../../../utils/crypto/providers';
import { formatAddressLong } from '../../../utils/user';
import { CheckCircle, Copy } from 'react-feather';
import { isMobile } from 'react-device-detect';
import PaymentModal from './PaymentModal';
import usePaymentModal from './PaymentModal/usePaymentModal';

export default function PaymentButtons({ user, theme }) {
  const { activeWallets } = user;
  if (activeWallets.length === 0) {
    return null;
  }
  const walletsByProvider = {
    metamask: [],
    stacks: [],
    phantom: [],
  };

  // Group wallets by their provider
  activeWallets.forEach((wallet) => {
    walletsByProvider[wallet.provider].push(wallet);
  });

  return (
    <div className="flex-col space-y-6 w-screen mt-6">
      {Object.keys(walletsByProvider).map((provider) => {
        if (walletsByProvider[provider].length === 0) {
          return null;
        }
        return (
          <PaymentButton
            key={provider}
            provider={provider}
            wallets={walletsByProvider[provider]}
            theme={theme}
          />
        );
      })}
    </div>
  );
}

const PaymentButton = ({ provider, wallets, theme }) => {
  const [isHidden, setIsHidden] = useState(true);
  const [activeWallet, setActiveWallet] = useState(null);
  const currency = CURRENCIES[provider];
  const { show, handleOpen, handleClose } = usePaymentModal();
  const handleTransactionModal = (wallet) => {
    setActiveWallet(wallet);
    handleOpen();
  };

  return (
    <>
      <div
        className="mx-auto"
        style={{
          maxWidth: isMobile ? 'auto' : 600,
          padding: isMobile ? '0px 5px' : '',
        }}>
        <div
          onClick={() => setIsHidden(!isHidden)}
          style={{
            maxWidth: isMobile ? 'auto' : 600,
            ...theme.buttonStyles,
          }}
          className={`
            w-full text-xl
            p-3 text-center 
            cursor-pointer
            ${theme.buttonClasses}
          `}>
          Me on {currency.name}
        </div>
        <Transition show={!isHidden}>
          <Transition.Child
            enter="transition-opacity ease-in-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-in-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div
              className={`mt-2 
              flex flex-col mx-1 md:mx-4 items-center justify-center 
              bg-white rounded-lg shadow border border-gray-100`}>
              <ul className="flex flex-col divide divide-y w-full">
                {wallets.map((wallet, i) => (
                  <PaymentAddress
                    key={i}
                    wallet={wallet}
                    handleOpen={handleTransactionModal}
                    theme={theme}
                  />
                ))}
              </ul>
            </div>
          </Transition.Child>
        </Transition>
      </div>
      {show && <PaymentModal wallet={activeWallet} handleClose={handleClose} />}
    </>
  );
};

const PaymentAddress = ({ wallet, handleOpen, theme }) => {
  const { address, provider } = wallet;
  const currency = CURRENCIES[provider];
  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(address);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return (
    <li className="flex flex-row w-full">
      <div className="select-none cursor-pointer flex flex-1 items-center p-4">
        <div className="flex-1 pl-1 mr-16">
          <div
            className="font-medium text-gray-600 flex items-center"
            onClick={handleCopy}>
            {formatAddressLong(address)}
            {copied ? (
              <>
                {isMobile ? (
                  <CheckCircle size={13} className="ml-2 text-green-400" />
                ) : (
                  <p className="text-green-400 text-xs ml-2">address copied!</p>
                )}
              </>
            ) : (
              <Copy
                size={13}
                data-tip="Copy this address"
                className="ml-2 text-gray-500 hover:text-blue-500"
              />
            )}
          </div>
          <a
            href={currency.scanner(address)}
            rel="nofollow noreferrer"
            target="_blank"
            className="text-gray-400 hover:text-blue-500 text-xs underline">
            view on {currency.scannerLabel}
          </a>
        </div>
        <button
          style={theme.paymentButtonStyles}
          onClick={() => handleOpen(wallet)}
          className={`
            ${theme.paymentButtonClasses}
            p-2 text-sm 
            rounded-lg`}>
          send {currency.symbol}
        </button>
      </div>
    </li>
  );
};
