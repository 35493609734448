// All of these functions will handle some specific wallet login and will then return
// the user model that exists or was create in Firestore. This result should always be set to the context
import metamask from './metamask';
import stacks from './stacks';
import phantom from './phantom';

const PROVIDERS = {
  metamask,
  stacks,
  phantom,
};

// Scanner URLs will always default to mainnet
export const CURRENCIES = {
  metamask: {
    name: 'Ethereum',
    symbol: 'ETH',
    scannerLabel: 'etherscan',
    scanner: (address) => `https://etherscan.io/address/${address}`,
    scannerTxn: (txnHash) => `https://etherscan.io/tx/${txnHash}`,
  },
  stacks: {
    name: 'Stacks',
    symbol: 'STX',
    scannerLabel: 'stacks explorer',
    scanner: (address) => `https://explorer.stacks.co/address/${address}`,
    scannerTxn: (txnHash) => `https://explorer.stacks.co/txid/${txnHash}`,
  },
  phantom: {
    name: 'Solana',
    symbol: 'SOL',
    scannerLabel: 'solana explorer',
    scanner: (address) => `https://explorer.solana.com/address/${address}`,
    scannerTxn: (txnHash) =>
      `https://explorer.solana.com/tx/${txnHash}?cluster=devnet`,
  },
};

export default PROVIDERS;
