import React from 'react';
import THEME from '../../../../utils/theme';
import CustomTheme from './customTheme';
import { CheckCircle } from 'react-feather';

export default function Themes({
  user,
  currentTheme,
  applyTheme,
  handleCustomUpload,
}) {
  return (
    <div className="flex-col w-full bg-white rounded-lg shadow-md p-4">
      <h2 className="font-semibold text-gray-800 text-2xl">
        Select your display theme
      </h2>
      <p className="text-sm text-gray-500">
        Here you can select a theme to add some spice 🌶️ to your profile.
      </p>

      <div className="w-full grid grid-cols-1 md:grid-cols-5 gap-y-6 p-2 mt-6">
        {Object.keys(THEME)
          .filter((key) => key !== 'custom')
          .map((key) => {
            const theme = THEME[key];
            const active = currentTheme === key;

            return (
              <div
                key={key}
                className="border border-gray-200 rounded-2xl w-48 ">
                <img
                  alt={key}
                  src={theme.preview}
                  style={theme.previewStyles}
                  className="rounded-t-lg h-48 w-auto"
                />
                <div className="flex items-center justify-between gap-4 w-full p-2 ">
                  <button
                    onClick={() => applyTheme(key)}
                    type="button"
                    className={`
                      w-full 
                      py-2 px-4 bg-transparent hover:bg-green-500

                      border ${
                        active ? 'border-green-400' : 'border-gray-400'
                      } hover:border-transparent
                      ${
                        active ? 'text-green-500' : 'text-gray-500'
                      } text-gray-500 hover:text-white
                      text-center text-base font-regular
                      transition ease-in duration-200 
                      shadow-md rounded-lg`}>
                    {active ? (
                      <div className="w-full justify-center flex">
                        <CheckCircle className="mr-2" /> {theme.name}
                      </div>
                    ) : (
                      <>{theme.name}</>
                    )}
                  </button>
                </div>
              </div>
            );
          })}
        <CustomTheme
          user={user}
          currentTheme={currentTheme}
          handleCustomUpload={handleCustomUpload}
          applyTheme={applyTheme}
        />
      </div>
    </div>
  );
}
