import User from '../../models/user';
import MetaMaskLogo from '../../media/metamask-icon.svg';
// https://docs.metamask.io/guide/getting-started.html

async function loginWithMetaMask() {
  const { ethereum } = window;
  if (!ethereum) {
    return false;
  }

  const accounts = await ethereum
    .request({ method: 'eth_requestAccounts' })
    .catch((e) => {
      console.info(e.message);
      return [null];
    });
  const userAddress = accounts[0];
  if (!userAddress) {
    return false;
  }
  const dbUser = await User.createOrFind(userAddress, METAMASK.provider);

  return dbUser;
}

async function connectWithMetaMask(existingUser) {
  const { ethereum } = window;
  if (!ethereum || !existingUser) {
    return false;
  }

  const request = await ethereum
    .request({
      method: 'wallet_requestPermissions',
      params: [
        {
          eth_accounts: {},
        },
      ],
    })
    .catch((e) => {
      console.info(e.message);
      return [null];
    });

  const availableWallets = request[0].caveats.find(
    (cavet) => cavet.name === 'exposedAccounts',
  )?.value;
  const newWallets = availableWallets.filter(
    (wallet) => !existingUser.addresses.includes(wallet),
  );
  const newAddress = newWallets.length > 0 ? newWallets[0] : null;

  if (!newAddress) {
    return false;
  }

  // add the new wallet to the address, provider, and active wallets
  const updatedInfo = {
    addresses: [...existingUser.addresses, newAddress],
    providers: [...existingUser.providers, METAMASK.provider],
    activeWallets: [
      ...existingUser.activeWallets,
      {
        address: newAddress,
        provider: METAMASK.provider,
        priority:
          existingUser.activeWallets.length > 0
            ? existingUser.activeWallets[existingUser.activeWallets.length - 1]
                .priority + 1
            : 0,
      },
    ],
  };

  const dbUser = await User.updateProfile(updatedInfo, existingUser.uid);
  return dbUser;
}

async function sendTransaction(to, value) {
  const accounts = await window.ethereum
    .request({ method: 'eth_requestAccounts' })
    .catch((e) => {
      console.info(e.message);
      return [null];
    });
  const senderAddress = accounts[0];
  if (!senderAddress) {
    return false;
  }

  const result = await window.ethereum
    .request({
      method: 'eth_sendTransaction',
      params: [
        {
          from: senderAddress,
          to,
          value: (value * 1e18).toString(16), // convert value to wei and hex encode
        },
      ],
    })
    .then((txHash) => {
      console.log(txHash);
      return txHash;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });

  return result;
}

const METAMASK = {
  provider: 'metamask',
  name: 'MetaMask',
  logo: MetaMaskLogo,
  login: loginWithMetaMask,
  connect: connectWithMetaMask,
  sendTx: sendTransaction,
};

export default METAMASK;
