import React from 'react';
import { useParams } from 'react-router';
import paths from '../../paths';

export default function NoUser() {
  const { username } = useParams();

  return (
    <div className="h-screen w-screen overflow-hidden bg-black flex flex-col justify-center items-center space-y-2">
      <div class="text-white">@{username} is not on Blockl.ink yet.</div>
      <a
        href={paths.root()}
        className="p-2 border border-white bg-transparent hover:bg-white text-white hover:text-black rounded-md">
        &larr; Blockl.ink
      </a>
    </div>
  );
}
