import User from '../../models/user';
import PhantomLogo from '../../media/phantom-icon.svg';
import {
  Connection,
  Transaction,
  SystemProgram,
  clusterApiUrl,
  LAMPORTS_PER_SOL,
  PublicKey,
} from '@solana/web3.js';
//https://docs.phantom.app/integrating/detecting-the-provider

const NETWORK = clusterApiUrl('mainnet-beta', 'confirmed');

// we specifically target Phantom Solana here.
async function loginWithPhantom() {
  const { solana } = window;
  if (!solana || !solana?.isPhantom) {
    return false;
  }

  const userAddress = await new Promise((resolve) => {
    solana.connect();
    const timeout = setTimeout(() => {
      console.error('Phantom did not respond in time.');
      resolve(null);
    }, 10000);

    solana.on('connect', () => {
      const address = solana.publicKey.toString();
      clearTimeout(timeout);
      resolve(address);
    });
  });

  if (!userAddress) {
    return false;
  }
  const dbUser = await User.createOrFind(userAddress, PHANTOM.provider);

  return dbUser;
}

async function connectWithPhantom(existingUser) {
  const { solana } = window;
  if (!solana || !solana?.isPhantom || !existingUser) {
    return false;
  }

  const newAddress = await new Promise((resolve) => {
    solana.connect();
    const timeout = setTimeout(() => {
      console.error('Phantom did not respond in time.');
      resolve(null);
    }, 10000);

    solana.on('connect', () => {
      const address = solana.publicKey.toString();
      clearTimeout(timeout);
      resolve(address);
    });
  });

  if (!newAddress || existingUser.addresses.includes(newAddress)) {
    return false;
  }

  // add the new wallet to the address, provider, and active wallets
  const updatedInfo = {
    addresses: [...existingUser.addresses, newAddress],
    providers: [...existingUser.providers, PHANTOM.provider],
    activeWallets: [
      ...existingUser.activeWallets,
      {
        address: newAddress,
        provider: PHANTOM.provider,
        priority:
          existingUser.activeWallets.length > 0
            ? existingUser.activeWallets[existingUser.activeWallets.length - 1]
                .priority + 1
            : 0,
      },
    ],
  };

  const dbUser = await User.updateProfile(updatedInfo, existingUser.uid);
  return dbUser;
}

async function sendTransaction(to, value) {
  const { solana } = window;
  if (!solana || !solana?.isPhantom) {
    return false;
  }
  const senderAddress = await new Promise((resolve) => {
    solana.connect();
    const timeout = setTimeout(() => {
      console.error('Phantom did not respond in time.');
      resolve(null);
    }, 10000);

    solana.on('connect', () => {
      const address = solana.publicKey.toString();
      clearTimeout(timeout);
      resolve(address);
    });
  });
  if (!senderAddress) {
    return false;
  }

  const connection = new Connection(NETWORK);
  const recieverWallet = new PublicKey(to);
  const senderWallet = new PublicKey(senderAddress);
  const transaction = new Transaction().add(
    SystemProgram.transfer({
      fromPubkey: senderWallet,
      toPubkey: recieverWallet,
      lamports: LAMPORTS_PER_SOL * value,
    }),
  );

  transaction.feePayer = await solana.publicKey;
  const blockhashObj = await connection.getRecentBlockhash();
  transaction.recentBlockhash = await blockhashObj.blockhash;
  transaction && console.log('Txn created successfully');

  try {
    const signed = await solana.signTransaction(transaction);
    const signature = await connection.sendRawTransaction(signed.serialize());
    return signature;
  } catch (e) {
    console.error(e);
    console.error('Txn failed to send');
    return false;
  }
}

const PHANTOM = {
  provider: 'phantom',
  name: 'Phantom',
  logo: PhantomLogo,
  login: loginWithPhantom,
  connect: connectWithPhantom,
  sendTx: sendTransaction,
};

export default PHANTOM;
