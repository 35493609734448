import React from 'react';
import Jdenticon from 'react-jdenticon';

const PHOTO_SIZE = 130;

export default function ProfileImage({ user }) {
  if (user.profileImage) {
    return (
      <div className="w-full flex justify-center">
        <img
          alt="Your profile avatar"
          src={user.profileImage}
          style={{
            width: PHOTO_SIZE,
            height: PHOTO_SIZE,
          }}
          className="rounded-full"
        />
      </div>
    );
  }

  return (
    <div className="w-full flex justify-center">
      <div
        className="jdenticon-continer"
        style={{
          backgroundColor: '#FFF',
          width: PHOTO_SIZE,
          height: PHOTO_SIZE,
          clipPath: 'circle(65px at center)',
        }}>
        <Jdenticon size={PHOTO_SIZE.toString()} value={user.username} />
      </div>
    </div>
  );
}
