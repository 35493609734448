import React from 'react';
import Links from './Links';
import Wallets from './Wallets';
import NFTGallery from './NFTGallery';

export default function Content({ onUpdate }) {
  return (
    <div className="col-span-10 md:col-span-7 h-screen border-r border-gray-300 p-4">
      <div className="flex grid grid-cols-10 md:grid-cols-6 w-full space-x-0 md:space-x-8">
        <div className="col-span-10 md:col-span-2 flex flex-col justify-start">
          <Wallets onUpdate={onUpdate} />
        </div>

        <div className="col-span-10 md:col-span-4 flex flex-col space-y-6">
          <Links onUpdate={onUpdate} />
          <NFTGallery />
        </div>
      </div>
    </div>
  );
}
