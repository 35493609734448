import React, { useState, useContext } from 'react';
import PROVIDERS from '../../utils/crypto/providers';
import { Loader, X } from 'react-feather';
import { AuthContext } from '../../AuthContext';
import MetaMaskWordmark from '../../media/metamask-wordmark.svg';
import StacksLogo from '../../media/stx-circle.svg';
import PhantomLogo from '../../media/phantom-icon.svg';
import PhantomText from '../../media/phantom-text.svg';

export default function ConnectModal({ handleClose }) {
  const context = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const handleConnect = async (provider) => {
    setError(null);
    setLoading(true);
    const dbUser = await PROVIDERS[provider].connect(context.store.user);

    if (dbUser) {
      context.actions.updateUser(dbUser, provider);
      handleClose();
    } else {
      setError(`We could not connect you with ${provider}.`);
    }

    setLoading(false);
  };

  return (
    <div
      className="fixed z-10"
      style={{ width: '100vw', top: 0, height: '100vh' }}>
      <div className="h-screen w-full z-20 inset-0 overflow-y-auto">
        <div
          className="absolute w-full h-full inset-0 bg-gray-500 opacity-75"
          onClick={handleClose}></div>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"></span>
          <div
            className="inline-block relative overflow-hidden transform transition-all sm:align-middle sm:max-w-lg"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline">
            <div>
              <div className="rounded-lg p-8 bg-white shadow">
                <div className="absolute right-4 top-2">
                  <button
                    onClick={handleClose}
                    className="bg-transparent border border-transparent">
                    <X size={15} />
                  </button>
                </div>
                <div className="flex-col justify-center">
                  {loading ? (
                    <Loader
                      className="animate-spin"
                      size={40}
                      color="#9CA3AF"
                    />
                  ) : (
                    <div
                      className="flex-col justify-center"
                      style={{ width: '200px' }}>
                      <p className="text-base text-xl font-semibold text-gray-800">
                        Connect another wallet
                      </p>
                      {error && <p className="text-xs text-red-400">{error}</p>}
                      <div className="w-3/4 flex-col space-y-4 mt-4 mx-auto">
                        <button
                          onClick={() => handleConnect('metamask')}
                          className="flex w-full border border-gray-200 rounded-lg hover:bg-gray-100">
                          <img
                            alt="Metamask Login"
                            className="h-auto w-auto"
                            src={MetaMaskWordmark}
                          />
                        </button>

                        <button
                          onClick={() => handleConnect('stacks')}
                          className="flex px-3 py-3 w-full border border-gray-200 rounded-lg hover:bg-gray-100">
                          <img
                            alt="Hiro Wallet Stacks Login"
                            className="h-6 w-6"
                            src={StacksLogo}
                          />{' '}
                          <p className="text-gray-800 font-semibold ml-3">
                            Hiro Wallet
                          </p>
                        </button>

                        <button
                          onClick={() => handleConnect('phantom')}
                          className="flex space-x-3 px-3 py-3 w-full border border-gray-200 rounded-lg hover:bg-gray-100">
                          <img
                            alt="Phantom wallet"
                            className="h-6 w-6"
                            src={PhantomLogo}
                          />
                          <img
                            alt="Phantom wordmark"
                            className="h-6 w-20"
                            src={PhantomText}
                          />
                        </button>
                      </div>
                      <p className="text-xs text-gray-500 mt-5">
                        Don't see your wallet?{' '}
                        <p className="underline text-blue-500" href="#">
                          Tell us.
                        </p>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
