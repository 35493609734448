import React, { useEffect, useState } from 'react';
import ProfileImage from './profileImage';
import PaymentButtons from './paymentButtons';
import OtherLinks from './otherLinks';
import User from '../../../models/user';
import UserLink from '../../../models/userLink';
import paths from '../../../paths';
import THEMES from '../../../utils/theme';
import { useParams } from 'react-router';

export default function Main() {
  const { username } = useParams();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [userLinks, setUserLinks] = useState([]);
  const [theme, setTheme] = useState(THEMES.default);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await User.findByUsername(username);
      if (!user) {
        setUser(false);
        setLoading(false);
        return;
      }
      setUser(user);
      setTheme(
        user.theme && Object.keys(THEMES).includes(user.theme)
          ? THEMES[user.theme]
          : THEMES.default,
      );

      const userLinks = await UserLink.getAllEnabledLinks(user.uid);
      if (userLinks.length > 1) {
        userLinks.sort((a, b) => (a.priority > b.priority ? 1 : -1));
      }
      setUserLinks(userLinks);
      setLoading(false);
    };

    fetchUser(username);
  }, [username]);

  if (loading) {
    return null;
  }

  if (user === false) {
    window.location.replace(paths.user.notfound(username));
  }

  const backgroundStyles =
    theme.name === 'Custom'
      ? {
          background: `url(${user.customThemeBG}) center center`,
          backgroundSize: 'cover',
        }
      : theme.backgroundStyles;

  return (
    <div
      className={`w-screen h-screen flex justify-center overflow-scroll ${theme.backgroundClasses}`}
      style={backgroundStyles}>
      <div className="w-auto flex-col items-center my-4 py-8">
        <ProfileImage user={user} />
        <Details user={user} theme={theme} />
        <PaymentButtons user={user} theme={theme} />
        <OtherLinks links={userLinks} theme={theme} />
        <br />
      </div>
    </div>
  );
}

const Details = ({ user, theme }) => {
  const { displayName, bio, username } = user;

  return (
    <div className="flex-col items-center">
      <p className="text-lg text-center" style={theme.nameStyles}>
        {displayName || `@${username}`}
      </p>
      {bio && (
        <p className="text-base text-center" style={theme.bioStyles}>
          {bio}
        </p>
      )}
    </div>
  );
};
