import React, { useEffect } from 'react';
import WelcomeLayout from '../../layouts/WelcomeLayout';
import useUser from '../../hooks/useUser';
import Logo from '../../media/logo.svg';
import BGImg from '../../media/bg.png';
import paths from '../../paths';
import { isMobile } from 'react-device-detect';

export default function Welcome() {
  const { user } = useUser();
  useEffect(() => {
    !user.username && window.location.replace(paths.user.welcome());
  }, [user]);

  return (
    <WelcomeLayout>
      <header
        className="h-20 flex items-center z-30 w-full"
        style={{
          backgroundImage: isMobile ? `url(${BGImg})` : null,
          backgroundSize: 'cover',
        }}>
        <div className="container mx-auto px-6 flex items-center justify-between">
          <a href={paths.root()}>
            <div className="dark:text-white flex items-baseline">
              <img
                alt="BlockLink"
                src={Logo}
                className="h-6 filter invert md:invert-0"
              />
            </div>
          </a>
        </div>
      </header>
      <div className="container mx-6 md:mx-auto w-5/6 md:w-1/2 flex-col space-y-8 mt-18 pt-10 md:pt-4 mb-10">
        <div className="flex">
          <div className="flex-col mx-auto space-y-2">
            <h1 className="text-2xl md:text-3xl font-semibold text-gray-800">
              Okay{' '}
              <code className="bg-purple-500 p-1 text-white rounded-lg">
                @{user.username}
              </code>
              , you're all set!
            </h1>
            <p className="text-gray-500">
              Your username has been locked down and its yours to keep now! We
              will send an email out to everyone when we launch!
              <br />
              <br />
              If you did not leave an email - well then just follow
              <a
                href="https://twitter.com/tcarambat"
                target="_blank"
                rel="nofollow noreferrer"
                className="pl-1 text-blue-500 underline">
                Timothy Carambat on Twitter
              </a>{' '}
              for the launch tweet!
            </p>

            <div className="flex-col mx-auto space-y-2 pt-8">
              <h1 className="text-3xl font-semibold text-gray-800">
                Here is what you can expect...
              </h1>
              {PROMOS.map(({ boldText, bodyText }, i) => {
                return (
                  <div className="flex mx-auto py-6 items-center space-x-2">
                    {(i + 1) % 2 === 1 && (
                      <p className="text-6xl text-purple-500 font-semibold">
                        {i + 1}
                      </p>
                    )}
                    <p className="text-base text-gray-600">
                      <b>{boldText}</b> {bodyText}
                    </p>
                    {(i + 1) % 2 === 0 && (
                      <p className="text-6xl text-purple-500 font-semibold">
                        {i + 1}
                      </p>
                    )}
                  </div>
                );
              })}
              <h1 className="text-3xl font-semibold text-gray-800">
                So what's next? Share the word!
              </h1>
            </div>
          </div>
        </div>
      </div>
    </WelcomeLayout>
  );
}

const PROMOS = [
  {
    boldText: 'Mulit-Wallet Authentication.',
    bodyText: `Using multiple wallets provider connect any and all your wallets.
    Each wallet you connect with also doubles as another way to login - so no need to remember which one you logged in with.`,
  },
  {
    boldText: 'Mulit-Wallet Payment.',
    bodyText: `From your profile page you can get paid to any listed address using Web3.`,
  },
  {
    boldText: 'Social Links in one place.',
    bodyText: `In addition to your crypto addresses, you can also have all your social links on BlockLink!`,
  },
  {
    boldText: 'NFT Gallery.',
    bodyText: `We know you collect, but your more dynamic than a single blockchain - let alone a single project. 
    We enable you to show off your collection on mutiple chains.`,
  },
  {
    boldText: 'Custom Profiles.',
    bodyText: `Be able to call your profile your own with our pre-set and (coming soon) custom backgrounds`,
  },
  {
    boldText: 'No Gas.',
    bodyText: `Adding another payment destination wont cost you $300 in fees. BlockLinks lets you add that address that for free.`,
  },
];
