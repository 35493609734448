import React, { useContext, useState } from 'react';
import ConnectModal from '../../../../components/ConnectModal';
import useLoginModal from '../../../../hooks/useLoginModal';
import PROVIDERS from '../../../../utils/crypto/providers';
import User from '../../../../models/user';
import { formatAddress } from '../../../../utils/user';
import { XCircle, Zap } from 'react-feather';
import { AuthContext } from '../../../../AuthContext';

export default function Wallets({ onUpdate }) {
  const context = useContext(AuthContext);
  const { user } = context.store;
  const { show, handleOpen, handleClose } = useLoginModal();
  const handleToggle = async (address, status) => {
    const activeWallets = [...user.activeWallets];
    const indexOfAddress = user.addresses.indexOf(address);
    if (indexOfAddress === -1) {
      return false;
    }

    if (!status) {
      const indexOfActiveWallet = activeWallets.findIndex(
        (wallet) => wallet.address === address,
      );
      activeWallets.splice(indexOfActiveWallet, 1);
    } else if (status) {
      const newItem = {
        address,
        provider: user.providers[indexOfAddress],
        priority: user.activeWallets.length,
      };
      activeWallets.push(newItem);
    }

    // recalc priority so it always in order of wallets
    activeWallets.forEach((wallet) => {
      wallet.priority = user.addresses.indexOf(wallet.address);
    });

    const dbUser = await User.updateProfile({ activeWallets }, user.uid);
    dbUser && context.actions.updateUser(dbUser, dbUser.providers[0]);
    onUpdate();
  };
  const handleRemove = async (address, activeWalletIdx) => {
    if (
      window.confirm(
        'Are you sure you want to remove this wallet? This action cannot be undone.',
      )
    ) {
      const addressAndProviderIdx = user.addresses.indexOf(address);
      if (addressAndProviderIdx !== -1) {
        user.addresses.splice(addressAndProviderIdx, 1);
        user.providers.splice(addressAndProviderIdx, 1);
      }
      user.activeWallets.splice(activeWalletIdx, 1);

      const updatedInfo = {
        addresses: [...user.addresses],
        providers: [...user.providers],
        activeWallets: [...user.activeWallets],
      };
      const dbUser = await User.updateProfile(updatedInfo, user.uid);

      if (dbUser) {
        context.actions.updateUser(dbUser, updatedInfo.providers[0]);
        handleClose();
      } else {
        console.error(`Address: ${address} could not be removed.`);
      }
      onUpdate();
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
        <div className="px-4 py-5 sm:px-6 border-b w-full">
          <h3 className="text-lg font-semibold leading-6 text-gray-800 dark:text-white">
            Connected Wallets
          </h3>
          <p className="mt-1 max-w-2xl text-xs text-gray-500">
            These are wallets you have authorized and can login with on
            BlockLink. You can add, remove, or show them on your page.
            <br />
            <br />
            Flip the toggle to have this address appear as a link on your page
          </p>
        </div>
        <ul className="flex flex-col divide divide-y w-full">
          {user.addresses.map((address, i) => {
            const provider = user.providers[i];
            const enabled = !!user.activeWallets.find(
              (wallet) => wallet.address === address,
            );

            return (
              <WalletItem
                key={i}
                address={address}
                provider={provider}
                isEnabled={enabled}
                onToggle={handleToggle}
                canRemove={user.addresses.length > 1}
                handleRemove={() => handleRemove(address, i)}
              />
            );
          })}
          <li className="flex flex-row">
            <div className="select-none cursor-pointer flex flex-1 items-center p-4">
              <button
                onClick={handleOpen}
                className="w-full flex space-x-2 justify-center items-center rounded-lg p-3 bg-purple-500 hover:bg-purple-600">
                <Zap size={20} color="white" />
                <p className="text-white text-lg">Connect another wallet</p>
              </button>
            </div>
          </li>
        </ul>
      </div>
      {show && <ConnectModal handleClose={handleClose} />}
    </>
  );
}

const WalletItem = ({
  address,
  provider,
  isEnabled,
  onToggle,
  canRemove,
  handleRemove,
}) => {
  const [enabled, setEnabled] = useState(isEnabled);
  const handleToggle = ({ target: { checked } }) => {
    setEnabled(checked);
    onToggle(address, checked);
  };

  return (
    <li className="flex flex-row">
      <div className="select-none cursor-pointer flex flex-1 items-center p-4">
        <div className="flex flex-col w-16 md:w-20 h-10 justify-center items-center mr-0 md:mr-4">
          <img
            alt="provider"
            src={PROVIDERS[provider].logo}
            className="mx-auto object-cover rounded-full h-10 w-10 md:h-12 md:w-12 "
          />
        </div>
        <div className="flex-1 pl-1 mr-16">
          <div className="font-medium text-gray-500">
            {formatAddress(address)}
          </div>
        </div>
        <div className="text-gray-600 dark:text-gray-200 text-xs">
          <div className="flex flex-col whitespace-nowrap items-center justify-center">
            <div className="relative inline-block w-10 mr-2 align-middle select-none">
              <input
                onClick={handleToggle}
                readOnly={true}
                checked={enabled}
                type="checkbox"
                name="toggle"
                className="checked:bg-green-500 outline-none focus:outline-none right-4 checked:right-0 duration-200 ease-in absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
              />
              <label
                className={`block overflow-hidden h-6 rounded-full ${
                  enabled ? 'bg-green-300' : 'bg-gray-300'
                } cursor-pointer`}
              />
            </div>
          </div>
        </div>

        <button
          onClick={handleRemove}
          title="remove this wallet address"
          className="w-auto text-right flex justify-end text-gray-400 hover:text-red-400">
          {canRemove && <XCircle size={20} />}
        </button>
      </div>
    </li>
  );
};
