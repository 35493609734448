import React from 'react';
import paths from '../../paths';

export default function Generic() {
  return (
    <div className="h-screen w-screen overflow-hidden bg-black flex justify-center items-center">
      <div class="error text-white">404</div>
      <span class="info text-white">File not found</span>
      <a
        href={paths.root()}
        style={{ marginTop: '250px' }}
        className="p-2 border border-white bg-transparent hover:bg-white text-white hover:text-black rounded-md">
        &larr; Blockl.ink
      </a>
    </div>
  );
}
