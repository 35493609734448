import {
  AppConfig,
  UserSession,
  showConnect,
  openSTXTransfer,
} from '@stacks/connect';
import { StacksMainnet } from '@stacks/network';
import User from '../../models/user';
import StacksLogo from '../../media/stx-circle.svg';
//https://github.com/blockstack/connect

const stacksAppConfig = new AppConfig(['publish_data']);
export const stacksUserSession = new UserSession({
  appConfig: stacksAppConfig,
});

export async function loginWithStacks() {
  const stacksLoginAuth = new Promise((resolve) => {
    showConnect({
      appDetails: {
        name: process.env.REACT_APP_APP_NAME,
        icon: window.location.origin + '/favicon.png',
      },
      onFinish: async (session) => {
        const {
          profile: {
            stxAddress: { testnet, mainnet },
          },
        } = session.authResponsePayload;
        const stxAddr =
          !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
            ? testnet
            : mainnet;

        if (!stxAddr) {
          return false;
        }
        const dbUser = await User.createOrFind(stxAddr, 'stacks');
        resolve(dbUser);
      },
      onCancel: () => {
        resolve(null);
      },
      userSession: stacksUserSession,
    });
  });

  return stacksLoginAuth;
}

export async function connectWithStacks(existingUser) {
  const stacksLoginAuth = new Promise((resolve) => {
    showConnect({
      appDetails: {
        name: process.env.REACT_APP_APP_NAME,
        icon: window.location.origin + '/favicon.png',
      },
      onFinish: async (session) => {
        const {
          profile: {
            stxAddress: { testnet, mainnet },
          },
        } = session.authResponsePayload;
        const newAddress =
          !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
            ? testnet
            : mainnet;

        if (!newAddress || existingUser.addresses.includes(newAddress)) {
          return false;
        }

        // add the new wallet to the address, provider, and active wallets
        const updatedInfo = {
          addresses: [...existingUser.addresses, newAddress],
          providers: [...existingUser.providers, STACKS.provider],
          activeWallets: [
            ...existingUser.activeWallets,
            {
              address: newAddress,
              provider: STACKS.provider,
              priority:
                existingUser.activeWallets.length > 0
                  ? existingUser.activeWallets[
                      existingUser.activeWallets.length - 1
                    ].priority + 1
                  : 0,
            },
          ],
        };

        const dbUser = await User.updateProfile(updatedInfo, existingUser.uid);
        resolve(dbUser);
      },
      onCancel: () => {
        resolve(null);
      },
      userSession: stacksUserSession,
    });
  });

  return stacksLoginAuth;
}

async function sendTransaction(to, value) {
  const stacksTxResponse = new Promise((resolve) => {
    openSTXTransfer({
      recipient: to,
      amount: value * 1e6, // 1STX = 1uSTX
      memo: 'From Blockl.ink',
      network: new StacksMainnet(),
      appDetails: {
        name: process.env.REACT_APP_APP_NAME,
        icon: window.location.origin + '/favicon.png',
      },
      onFinish: (data) => {
        console.log('Stacks Transaction:', data.stacksTransaction);
        console.log('STX Transaction ID:', data.txId);
        resolve(data.txId);
      },
      onCancel: () => {
        resolve(null);
      },
    });
  });

  return stacksTxResponse;
}

const STACKS = {
  provider: 'stacks',
  name: 'Hiro Wallet',
  logo: StacksLogo,
  login: loginWithStacks,
  connect: connectWithStacks,
  sendTx: sendTransaction,
};

export default STACKS;
