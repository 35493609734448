import firebase from '@firebase/app';
import '@firebase/firestore';
import app from '../base';

const UserLink = {
  collection: (userUid) => `users/${userUid}/links`,
  baseRecord: {
    type: 'link',
    title: null,
    url: null,
    enabled: true,
    priority: null,
    thumbnailURL: null,
    created_at: firebase.firestore.FieldValue.serverTimestamp(),
    updated_at: firebase.firestore.FieldValue.serverTimestamp(),
  },
  createLink: async (userUid, priority = 0) => {
    const newLink = await app
      .firestore()
      .collection(UserLink.collection(userUid))
      .add({
        ...UserLink.baseRecord,
        priority,
      })
      .then((docRef) => {
        return {
          uid: docRef.id,
          ...UserLink.baseRecord,
          priority,
        };
      })
      .catch((e) => {
        console.error(e);
        return null;
      });

    return newLink;
  },
  getUserLinks: async (userUid) => {
    const userLinks = await app
      .firestore()
      .collection(UserLink.collection(userUid))
      .get()
      .then((results) => {
        if (results.docs.length === 0) {
          return [];
        }

        return results.docs.map((doc) => {
          return { uid: doc.id, ...doc.data() };
        });
      })
      .catch((e) => {
        console.error(e);
        return [];
      });

    return userLinks;
  },
  getAllEnabledLinks: async (userUid) => {
    const userLinks = await app
      .firestore()
      .collection(UserLink.collection(userUid))
      .where('enabled', '==', true)
      .get()
      .then((results) => {
        if (results.docs.length === 0) {
          return [];
        }

        return results.docs.map((doc) => {
          return { uid: doc.id, ...doc.data() };
        });
      })
      .catch((e) => {
        console.error(e);
        return [];
      });

    return userLinks;
  },
  updateUserLink: async (userUid, linkUid, input) => {
    await app
      .firestore()
      .collection(UserLink.collection(userUid))
      .doc(linkUid)
      .update({ ...input })
      .catch(() => {
        console.error('Update of link failed.');
      });

    return;
  },
  deleteLink: async (userUid, linkUid) => {
    await app
      .firestore()
      .collection(UserLink.collection(userUid))
      .doc(linkUid)
      .delete()
      .catch(() => {
        console.error('Could not delete doc');
      });
  },
};

export default UserLink;
