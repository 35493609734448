import React, { useContext, useEffect, useState } from 'react';
import paths from '../../paths';
import Logo from '../../media/favicon.png';
import useChat from '../../hooks/useChat';
import { AuthContext } from '../../AuthContext';
import { isMobile } from 'react-device-detect';

export default function AppLayout({ user, content, preview }) {
  useChat();
  const context = useContext(AuthContext);
  const [copied, setCopied] = useState(false);
  const handleSignout = () => {
    context.actions.unsetUser();
    window.location.replace(paths.root());
  };
  const handleCopy = () => {
    navigator.clipboard.writeText(paths.user.profile(user, true));
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3500);
  };
  useEffect(() => {
    document.querySelector('body').classList.add('bg-gray-100');
  }, []);

  return (
    <div className="grid grid-cols-10 h-full overflow-hidden">
      <div className="col-span-10 md:col-span-7 overflow-scroll">
        <header className="bg-white h-14 w-full border-r border-b border-gray-300">
          <div className="md:mx-10 h-14 flex items-center justify-between">
            <div className="dark:text-white flex items-center">
              <a
                href={paths.user.manage.home()}
                className="hover:bg-gray-100 p-3 rounded-full">
                <img alt="BlockLink" src={Logo} className="h-8 filter" />
              </a>
              <a
                href={paths.user.manage.home()}
                className={`transition duration-500 ease-in-out h-auto text-sm py-2 mx-4 flex ${paths.isActive(paths.user.manage.home())
                  ? 'text-purple-800 border-purple-300'
                  : 'text-gray-600 border-transparent'
                  } hover:text-purple-800 border-b-4 hover:border-purple-300`}>
                Links & Wallets
              </a>
              <a
                href={paths.user.manage.appearances()}
                className={`transition duration-500 ease-in-out h-auto text-sm py-2 mx-4 flex ${paths.isActive(paths.user.manage.appearances())
                  ? 'text-purple-800 border-purple-300'
                  : 'text-gray-600 border-transparent'
                  } hover:text-purple-800 border-b-4 hover:border-purple-300`}>
                Appearance & More
              </a>
            </div>

            <div className="dark:text-white flex items-center w-16 md:w-auto">
              <button
                onClick={handleSignout}
                className="py-1 flex text-gray-600 text-sm">
                Sign out
              </button>
            </div>
          </div>
        </header>
        {content}
      </div>

      {!isMobile && (
        <div className="col-span-10 md:col-span-3">
          <header className="bg-white h-14 flex w-full border-b border-gray-300">
            <div className="p-6 flex w-full items-center justify-between">
              <p className="text-xs">
                <b>Your BlockLink:</b>{' '}
                <a
                  target="_blank"
                  rel="nofollow noreferrer"
                  className="underline hover:text-purple-500"
                  href={paths.user.profile(user)}>
                  {paths.user.profile(user, true)}
                </a>
              </p>
              <button
                onClick={handleCopy}
                className={`
              border ${copied ? 'border-green-300' : 'border-gray-300'} 
              py-1 px-3 
              ${copied ? 'text-green-500 ' : 'text-gray-500 '}
              rounded-lg text-sm hover:bg-gray-100`}>
                {copied ? 'Copied!' : 'Copy Link'}
              </button>
            </div>
          </header>
          {preview}
        </div>
      )}
    </div>
  );
}
