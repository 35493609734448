const paths = {
  root: () => {
    return '/';
  },
  user: {
    welcome: () => {
      return '/welcome';
    },
    welcomeComplete: () => {
      return '/welcome/complete';
    },
    notfound: (username) => {
      return `/notfound/@${username}`;
    },
    manage: {
      home: () => {
        return '/manage';
      },
      appearances: () => {
        return '/manage/appearances';
      },
    },
    profile: ({ username }, fullURL = false) => {
      return `${fullURL ? window.location.origin : ''}/@${username}`;
    },
    profileSandbox: ({ username }, fullURL = false) => {
      return `${fullURL ? window.location.origin : ''}/@${username}/sandbox`;
    },
  },
  isActive: (compPath) => {
    return window.location.pathname === compPath;
  },
};

export default paths;
