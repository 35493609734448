import React, { useRef, useState } from 'react';
import Jdenticon from 'react-jdenticon';
import { Loader } from 'react-feather';
import { getBase64, uploadPhotoToStorage } from '../../../../utils/storage';
import { isMobile } from 'react-device-detect';

export default function ProfileInfo({ user, updateProfile }) {
  const uploadRef = useRef(null);
  const [userInfo, setUserInfo] = useState({ ...user });
  const [uploading, setUploading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const emptyErrors = {
    displayName: null,
    bio: null,
  };
  const [errors, setErrors] = useState(emptyErrors);
  const handleInputUpdate = (field, value) => {
    setHasChanges(true);
    setErrors({ ...emptyErrors });
    setUserInfo({ ...userInfo, [field]: value });
  };
  const handleFileUpload = async (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      return;
    }

    setUploading(true);
    const base64Data = await getBase64(selectedFile);
    const imageURL = await uploadPhotoToStorage(base64Data, 'profile_images');

    handleInputUpdate('profileImage', imageURL);
    setUploading(false);
  };

  const handleSubmit = async () => {
    const { displayName, bio, profileImage } = userInfo;
    setSaving(true);

    if (displayName && displayName.length > 40) {
      setErrors({
        ...emptyErrors,
        displayName: 'Name is too long. Max of 40 characters',
      });
      setSaving(false);
      return false;
    }

    if (bio && bio.length > 100) {
      setErrors({
        ...emptyErrors,
        bio: 'Bio is too long. Max of 100 characters',
      });
      setSaving(false);
      return false;
    }

    await updateProfile({ displayName, bio, profileImage });
    setHasChanges(false);
    setSaving(false);
  };

  return (
    <div className="flex-col w-full bg-white rounded-lg shadow-md p-4 mb-4">
      <h2 className="font-semibold text-gray-800 text-2xl">
        Profile Information
      </h2>
      <p className="text-sm text-gray-500">
        Update your name, bio, and profile image
      </p>

      <div className={`flex${isMobile ? '-col' : ''} md:space-x-4 pt-4`}>
        <div className="flex">
          <div className="flex-col">
            <div>
              <h1 className="text-lg font-regular text-gray-800">
                Profile Avatar
              </h1>
            </div>
            <input
              ref={uploadRef}
              type="file"
              onChange={handleFileUpload}
              multiple={false}
              accept="image/png, image/gif, image/jpeg"
              style={{ display: 'none' }}
            />

            {uploading && (
              <div
                className="flex justify-center items-center"
                style={{
                  width: 100,
                  height: 100,
                  clipPath: 'circle(65px at center)',
                }}>
                <Loader className="animate-spin" size={40} color="#9CA3AF" />
              </div>
            )}

            {!uploading && (
              <>
                {userInfo.profileImage ? (
                  <img
                    onClick={() => uploadRef.current.click()}
                    alt="Your profile avatar"
                    src={userInfo.profileImage}
                    style={{
                      width: 160,
                      height: 160,
                    }}
                    className="rounded-full cursor-pointer"
                  />
                ) : (
                  <>
                    <div
                      onClick={() => uploadRef.current.click()}
                      className="jdenticon-continer cursor-pointer"
                      style={{
                        width: 160,
                        height: 160,
                        clipPath: 'circle(65px at center)',
                      }}>
                      <Jdenticon size="160" value={userInfo.username} />
                    </div>
                  </>
                )}
              </>
            )}
            {userInfo.profileImage && (
              <button
                className="text-gray-400 underline text-sm"
                onClick={() => handleInputUpdate('profileImage', '')}>
                remove image
              </button>
            )}
          </div>
        </div>

        <div
          className="flex flex-col"
          style={{ width: isMobile ? '100%' : 350 }}>
          <label class="text-gray-700 my-2">
            Name
            <p className="text-xs text-gray-500">
              Optional, will default to username.
            </p>
            {errors.displayName && (
              <p className="text-xs text-red-500">{errors.displayName}</p>
            )}
            <input
              value={userInfo.displayName}
              onChange={(e) => handleInputUpdate('displayName', e.target.value)}
              class="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
              placeholder="Who do you want to be known as?"
            />
          </label>

          <label class="text-gray-700 my-2">
            Bio
            <p className="text-xs text-gray-500">Optional, but nice to have.</p>
            {errors.bio && <p className="text-xs text-red-500">{errors.bio}</p>}
            <input
              value={userInfo.bio}
              onChange={(e) => handleInputUpdate('bio', e.target.value)}
              class="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
              placeholder="Tell the world something about yourself"
            />
          </label>
        </div>
      </div>

      {hasChanges && (
        <button
          type="button"
          onClick={handleSubmit}
          class="py-2 px-4 bg-transparent border border-purple-300 hover:bg-purple-500 hover:border-transparent text-purple-500 hover:text-white rounded-lg">
          {saving ? 'Saving...' : 'Save'}
        </button>
      )}
    </div>
  );
}
