import React, { useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Image, Loader, MoreVertical, Trash, XCircle } from 'react-feather';
import { SortableElement, sortableHandle } from 'react-sortable-hoc';
import {
  getBase64,
  uploadPhotoToStorage,
} from '../../../../../../utils/storage';

const LinkItem = SortableElement(({ dbLink, handleUpdate, handleDelete }) => {
  const thumbnailInput = useRef(null);
  const [link, setLink] = useState({ ...dbLink });
  const [uploading, setUploading] = useState(false);
  const { uid, title, url, thumbnailURL, enabled } = link;
  const onUpload = async (event) => {
    setUploading(true);
    const selectedFile = event.target.files[0];
    const base64Data = await getBase64(selectedFile);
    const linkThumbnailURL = await uploadPhotoToStorage(
      base64Data,
      'link_thumbnails',
    );
    if (!linkThumbnailURL) {
      setUploading(false);
      return false;
    }

    const updatedLink = { ...link, thumbnailURL: linkThumbnailURL };
    handleUpdate(link.uid, { ...updatedLink });
    setLink(updatedLink);
    setUploading(false);
  };
  const handleChange = ({ target: { name, value } }) => {
    setLink({ ...link, [name]: value });
  };
  const onDelete = () => {
    if (
      window.confirm(
        'Are you sure you want to delete this link? It cannot be recovered!',
      )
    ) {
      handleDelete(uid);
    }
  };
  const handleThumbnailRemove = () => {
    const updatedLink = { ...link, thumbnailURL: null };

    handleUpdate(link.uid, { ...updatedLink });
    setLink(updatedLink);
  };
  const handleToggle = () => {
    const updatedLink = { ...link, enabled: !enabled };

    handleUpdate(link.uid, { ...updatedLink });
    setLink(updatedLink);
  };
  const validateAndUpdate = () => {
    const { uid, title, url } = link;
    let validatedLink = link;

    if (title && !title.length > 0) {
      validatedLink = { ...link, title: dbLink.title };
    }

    if (url && !url.length > 0) {
      validatedLink = { ...link, url: dbLink.title };
    }
    setLink({ ...validatedLink });
    handleUpdate(uid, { ...validatedLink });
  };

  return (
    <li
      className="border-gray-400 flex flex-row mb-2"
      style={{ minHeight: 80 }}>
      <div className="shadow border h-full select-none bg-white dark:bg-gray-800 rounded-md flex flex-1 items-center">
        <DragHandle />
        {thumbnailURL && (
          <LinkThumbnail
            thumbnailURL={thumbnailURL}
            handleThumbnailRemove={handleThumbnailRemove}
          />
        )}
        <div className="flex flex-col w-full h-full space-y-2 justify-center">
          <div className="flex w-full justify-between">
            <input
              name="title"
              type="text"
              value={title}
              placeholder="Title of this link"
              className="h-auto text-sm font-semibold text-gray-800 outline-none border-b border-transparent focus:border-purple-300"
              style={{ width: 'inherit' }}
              onChange={handleChange}
              onBlur={validateAndUpdate}
              required={true}
              autoComplete={false}
            />
            <div className="w-full flex justify-end space-x-3 mr-2">
              <div className="relative inline-block w-10 mr-2 align-middle select-none">
                <input
                  onClick={handleToggle}
                  checked={enabled}
                  readOnly={true}
                  type="checkbox"
                  name="toggle"
                  className="checked:bg-green-500 outline-none focus:outline-none right-4 -top-1 checked:right-0 duration-200 ease-in absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                />
                <label
                  className={`block overflow-hidden h-4 rounded-full ${
                    enabled ? 'bg-green-300' : 'bg-gray-300'
                  } cursor-pointer`}
                />
              </div>
            </div>
          </div>
          <div className="flex w-full justify-between">
            <input
              name="url"
              type="url"
              value={url}
              placeholder="https://...."
              className="h-auto text-sm text-gray-600 outline-none"
              style={{ width: 'inherit' }}
              onChange={handleChange}
              onBlur={validateAndUpdate}
              required={true}
              autoComplete={false}
            />
            <div className="w-full flex justify-end space-x-3 mr-2">
              <input
                ref={thumbnailInput}
                type="file"
                accept="image/*"
                hidden
                onChange={onUpload}
              />
              {thumbnailURL && isMobile ? (
                <button
                  onClick={handleThumbnailRemove}
                  className="text-gray-300 hover:text-red-500"
                  data-tip="Remove the thumbnail for this link">
                  <XCircle size={20} />
                </button>
              ) : (
                <button
                  onClick={() => !uploading && thumbnailInput?.current.click()}
                  className="text-gray-300 hover:text-blue-500"
                  data-tip="Add a thumbnail to this link">
                  {uploading ? (
                    <Loader size={20} className="animate-spin" />
                  ) : (
                    <Image size={20} />
                  )}
                </button>
              )}
              <button
                onClick={onDelete}
                className="text-gray-300 hover:text-red-500">
                <Trash size={20} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
});

const DragHandle = sortableHandle(() => {
  return (
    <div className="cursor-move flex flex-col space-y-1 w-2 h-full justify-center items-center mr-4 border-r border-gray-300 p-2 px-3">
      <MoreVertical size={25} color="#9CA3AF" />
    </div>
  );
});

const LinkThumbnail = ({ thumbnailURL, handleThumbnailRemove }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="mr-4 relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <img
        alt="link thumbnail"
        src={thumbnailURL}
        className="h-12 w-12 rounded-lg"
      />
      {isHovered && (
        <button
          onClick={handleThumbnailRemove}
          className="text-red-500 absolute"
          style={{
            top: -9,
            right: -7,
            backgroundColor: '#FFF',
            borderRadius: 10,
          }}
          data-tip="Remove the thumbnail for this link">
          <XCircle size={20} />
        </button>
      )}
    </div>
  );
};

export default LinkItem;
