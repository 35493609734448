import React from 'react';
import useUser from '../../../../hooks/useUser';
import iPhone from '../../../../media/iPhone.png';
import paths from '../../../../paths';

export default function Preview({ refresh }) {
  const { user } = useUser();

  return (
    <div className="w-full h-8/10 pt-2 flex justify-center">
      <img
        alt="iPhone frame"
        src={iPhone}
        className="absolute"
        style={{ zIndex: 2, pointerEvents: 'none' }}
      />
      <div
        className="absolute"
        style={{
          width: 373,
          height: 829,
          marginTop: 26,
        }}>
        <iframe
          key={refresh}
          title={`${user.username} profile preview`}
          className="w-full h-full"
          frameBorder="false"
          src={paths.user.profileSandbox(user)}
          sandbox="allow-scripts allow-same-origin"
        />
      </div>
    </div>
  );
}
