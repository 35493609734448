import app from '../base';
import { v4 as uuidv4 } from 'uuid';

export const uploadPhotoToStorage = async (base64Data, folder) => {
  const filename = `${uuidv4()}.jpg`;
  const imageData = stripHeaderData(base64Data);
  const fileBuffer = Buffer.from(imageData, 'base64');
  const token = uuidv4();
  const options = {
    metadata: {
      contentType: 'image/jpeg',
      metadata: {
        firebaseStorageDownloadTokens: token,
      },
    },
  };

  const storedUrl = await app
    .storage()
    .ref(`${folder}/${filename}`)
    .put(fileBuffer, options)
    .then((snapshot) => snapshot.ref.getDownloadURL())
    .then((url) => {
      return url;
    });

  return storedUrl;
};

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function stripHeaderData(dataString) {
  return dataString.replace(/^data:.+;base64,/, '');
}
