import React from 'react';
import CTA from './cta';
import MarketingLayout from '../../../layouts/MarketingLayout';
import LoginModal from '../../../components/LoginModal';
import useLoginModal from '../../../hooks/useLoginModal';
import useUser from '../../../hooks/useUser';
import FeatureList from './features';

export default function Landing() {
  const { show, handleOpen, handleClose } = useLoginModal();
  const { user } = useUser();

  return (
    <>
      <MarketingLayout>
        <CTA user={user} handleOpen={handleOpen} />
        <FeatureList />
      </MarketingLayout>
      {show && <LoginModal handleClose={handleClose} />}
    </>
  );
}
