import React from 'react';
import { isMobile } from 'react-device-detect';

export default function OtherLinks({ links, theme }) {
  return (
    <div className="flex-col space-y-6 w-screen mt-6">
      {links.map((link, i) => {
        return <OtherLink key={i} link={link} theme={theme} />;
      })}
    </div>
  );
}

const OtherLink = ({ link, theme }) => {
  const { title, thumbnailURL } = link;
  return (
    <>
      <div
        className="mx-auto"
        style={{
          maxWidth: isMobile ? 'auto' : 360,
          padding: isMobile ? '0px 5px' : '',
        }}>
        <div
          style={{
            maxWidth: isMobile ? 'auto' : 360,
            ...theme.buttonStyles,
          }}
          className={`
            flex w-full text-xl
            p-3 text-center 
            cursor-pointer
            ${theme.buttonClasses}
          `}>
          {thumbnailURL && (
            <img
              alt={`${title} link`}
              src={thumbnailURL}
              className="rounded-lg bg-white h-8 w-8"
            />
          )}
          <div className="w-full text-center">{title}</div>
        </div>
      </div>
    </>
  );
};
