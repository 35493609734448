import React, { useState, createContext } from 'react';

export const AuthContext = createContext(null);
export function ContextWrapper(props) {
  const localUser = localStorage.getItem('user');
  const localProvider = localStorage.getItem('provider');
  const [store, setStore] = useState({
    user: localUser ? JSON.parse(localUser) : null,
    provider: localProvider ? localProvider : null,
  });

  const [actions] = useState({
    updateUser: (user, provider) => {
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('provider', provider);
      setStore({ user, provider });
    },
    unsetUser: () => {
      localStorage.removeItem('user');
      localStorage.removeItem('provider');
      setStore({ user: null, provider: null });
      window.location.replace('/');
    },
  });

  return (
    <AuthContext.Provider value={{ store, actions }}>
      {props.children}
    </AuthContext.Provider>
  );
}
