import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ContextWrapper } from './AuthContext';
import ReactTooltip from 'react-tooltip';
import PrivateRoute from './components/PrivateRoute';
import Marketing from './pages/Marketing';
import Welcome from './pages/Welcome';
import WelcomeComplete from './pages/Welcome/complete.js';
import NotFound from './pages/NotFound';

import Manage from './pages/Manage';
import Profile from './pages/Profile';

function App() {
  return (
    <ContextWrapper>
      <Router>
        <Switch>
          <Route exact path="/" component={Marketing.Landing} />
          <PrivateRoute exact path="/welcome" component={Welcome} />
          <PrivateRoute
            exact
            path="/welcome/complete"
            component={WelcomeComplete}
          />
          <Route exact path="/@:username" component={Profile.Main} />
          <Route exact path="/@:username/sandbox" component={Profile.Sandbox} />
          <PrivateRoute exact path="/manage" component={Manage.Home} />
          <PrivateRoute
            exact
            path="/manage/appearances"
            component={Manage.Appearances}
          />

          <Route
            exact
            path="/notfound/@:username"
            component={NotFound.NoUser}
          />
          <Route component={NotFound.Generic} />
        </Switch>
      </Router>
      <ReactTooltip
        type="dark"
        effect="solid"
        multiline={true}
        delayShow={100}
      />
    </ContextWrapper>
  );
}

export default App;
