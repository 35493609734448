import React, { useContext, useState } from 'react';
import { AuthContext } from '../../AuthContext';
import LoginModal from '../../components/LoginModal';
import useLoginModal from '../../hooks/useLoginModal';
import useUser from '../../hooks/useUser';
import paths from '../../paths';
import Logo from '../../media/logo.svg';

export default function MarketingHeader() {
  const context = useContext(AuthContext);
  const { show, handleOpen, handleClose } = useLoginModal();
  const { user } = useUser();
  const [menuOpen, setMenuOpen] = useState(false);
  const handleSignout = () => {
    context.actions.unsetUser();
    window.location.replace(paths.root());
  };

  return (
    <>
      <header className="h-24 sm:h-32 flex items-center z-30 w-full overflow-visible">
        <div className="container mx-auto px-6 flex items-center justify-between">
          <div className="dark:text-white flex items-baseline">
            <img alt="BlockLink" src={Logo} className="h-6 filter invert" />
            {/* <a href="#" className="text-base py-2 px-4 flex hover:text-black">
              About
            </a>
            <a href="#" className="text-base py-2 px-4 flex hover:text-black">
              Pricing
            </a> */}
          </div>
          <div className="flex items-center">
            <nav className="font-sen text-gray-800 dark:text-white text-md lg:flex items-center hidden">
              {user ? (
                <>
                  <button
                    onClick={handleSignout}
                    className="py-1 flex text-gray-100 opacity-50 hover:opacity-100 mr-6 border-b-4 border-transparent hover:border-purple-300">
                    Sign out
                  </button>
                  <a
                    href={
                      user.username
                        ? paths.user.manage.home()
                        : paths.user.welcome()
                    }
                    className="py-1 px-3 flex text-gray-100 bg-purple-500 rounded-lg border border-purple-500 hover:bg-purple-600">
                    Your Blocklink
                  </a>
                </>
              ) : (
                <button
                  onClick={handleOpen}
                  className="py-1 px-3 flex text-gray-100 rounded-lg border border-white hover:border-purple-500 hover:bg-purple-500">
                  Connect with wallet
                </button>
              )}
            </nav>
            <button
              className="transition duration-200 ease-in-out lg:hidden flex flex-col ml-4"
              onClick={() => setMenuOpen(!menuOpen)}
              style={{
                transform: menuOpen ? 'rotate(90deg)' : 'rotate(0deg)',
              }}>
              <span className="w-6 h-1 bg-white mb-1"></span>
              <span className="w-6 h-1 bg-white mb-1"></span>
              <span className="w-6 h-1 bg-white mb-1"></span>
            </button>
          </div>
        </div>
      </header>
      <div
        className={`${menuOpen ? 'relative' : 'fixed'} opacity-${
          menuOpen ? '100' : '0'
        } z-30 w-full transition duration-200 ease-in-out flex justify-center bg-white rounded-lg`}>
        <div className="flex-col items-baseline">
          <a
            href={paths.root()}
            className="text-base py-2 px-4 flex text-gray-500">
            Home
          </a>
        </div>
      </div>
      {show && <LoginModal handleClose={handleClose} />}
    </>
  );
}
