import React, { useState, useRef } from 'react';
import TransparentWhite from '../../../../media/themes/transparentWhite.png';
import { getBase64, uploadPhotoToStorage } from '../../../../utils/storage';
import { CheckCircle } from 'react-feather';

export default function CustomTheme({
  user,
  currentTheme,
  handleCustomUpload,
  applyTheme,
}) {
  const hasCustomPresent = !!user.customThemeBG;
  const customActive = currentTheme === 'custom';
  const uploadRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const handleButtonClick = () => {
    if (hasCustomPresent && !customActive) {
      applyTheme('custom');
      return;
    }
    uploadRef.current.click();
  };
  const handleFileUpload = async (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      return;
    }

    setUploading(true);
    const base64Data = await getBase64(selectedFile);
    const imageURL = await uploadPhotoToStorage(
      base64Data,
      'profile_backgrounds',
    );

    await handleCustomUpload(imageURL);
    setUploading(false);
  };

  return (
    <div
      className={`border border-gray-200 rounded-2xl w-48 
        ${
          !hasCustomPresent &&
          `bg-gradient-to-tr 
        from-yellow-400 via-pink-200 to-pink-400
        animate-gradient-xy`
        }`}>
      <img
        alt="Custom Upload"
        src={TransparentWhite}
        className="rounded-t-lg h-48 w-auto"
        style={{
          backgroundImage: hasCustomPresent ? `url(${user.customThemeBG})` : '',
          backgroundSize: 'cover',
        }}
      />
      <div className="flex-col items-center justify-between gap-4 w-full p-2 bg-white ">
        <input
          ref={uploadRef}
          type="file"
          onChange={handleFileUpload}
          multiple={false}
          accept="image/png, image/gif, image/jpeg"
          style={{ display: 'none' }}
        />
        <button
          onClick={handleButtonClick}
          disabled={uploading}
          type="button"
          className={`
                      w-full 
                      py-2 px-4 bg-transparent hover:bg-green-500
                      border ${
                        customActive ? 'border-green-400' : 'border-gray-400'
                      } hover:border-transparent
                      ${
                        customActive ? 'text-green-500' : 'text-gray-500'
                      } text-gray-500 hover:text-white
                      text-center text-base font-regular
                      transition ease-in duration-200 
                      shadow-md rounded-lg`}>
          {uploading ? (
            <>
              <p>Uploading...</p>
            </>
          ) : (
            <>
              {customActive ? (
                <div className="w-full justify-center flex">
                  <CheckCircle className="mr-2" /> Change
                </div>
              ) : (
                <>Upload Custom</>
              )}
            </>
          )}
        </button>
        <p className="text-gray-300 text-xs text-center py-1">
          JPG, PNG, or GIF
        </p>
      </div>
    </div>
  );
}
