import React from 'react';
// import { AuthContext } from '../../../../AuthContext';

export default function NFTGallery() {
  // const context = useContext(AuthContext);
  // const { user } = context.store;

  return (
    <div className="flex flex-col items-center justify-start bg-white dark:bg-gray-800 rounded-lg shadow">
      <div className="px-4 py-5 sm:px-6 border-b w-full">
        <h3 className="text-lg font-semibold leading-6 text-gray-800 dark:text-white">
          Wallet NFT Gallery
          <span className="ml-2 px-2 py-1 text-sm rounded-full text-gray-600 bg-gray-300 font-medium">
            coming soon
          </span>
        </h3>
        <p className="mt-1 max-w-2xl text-xs text-gray-500">
          Own some NFT's? Sure you do! You can show up to five select NFTs per
          wallet for 2 wallets.
          <br />
          <br />
          These will appear at the bottom of your page.
        </p>
      </div>
      <ul className="w-full flex flex-col divide divide-y">
        <li className="w-full flex flex-row">
          <div className="select-none cursor-pointer text-center justify-center flex flex-1 items-center p-4">
            <p className="text-gray-500 text-lg">
              This feature is still under development! <br />
              If you want to have this feature faster you can{' '}
              <a href="/@tdc" className="text-blue-500 underline">
                send the developer a tip ;)
              </a>
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
}
