import firebase from '@firebase/app';
import '@firebase/firestore';
import app from '../base';

const User = {
  collection: 'users',
  baseRecord: {
    providers: [],
    addresses: [],
    activeWallets: [
      //ex: {priority: 0, address: "0x11...dar4", provider: 'metamask'}
    ],
    username: null,
    displayName: null,
    bio: null,
    profileImage: null, // can fallback to Jdenticion https://www.npmjs.com/package/react-jdenticon
    theme: 'default',
    email: null,
    customThemeBG: null,
    created_at: firebase.firestore.FieldValue.serverTimestamp(),
    updated_at: firebase.firestore.FieldValue.serverTimestamp(),
  },
  createOrFind: async (userAddress, provider) => {
    const existingUser = await app
      .firestore()
      .collection(User.collection)
      .where('addresses', 'array-contains', userAddress)
      .get()
      .then((results) => {
        if (results.docs.length === 0) {
          return false;
        }
        const doc = results.docs[0];

        return { uid: doc.id, ...doc.data() };
      });
    if (existingUser !== false) {
      return existingUser;
    }

    const newUser = await app
      .firestore()
      .collection(User.collection)
      .add({
        ...User.baseRecord,
        addresses: [userAddress],
        providers: [provider],
        activeWallets: [
          { priority: 0, address: userAddress, provider: provider },
        ],
      })
      .then((docRef) => {
        return {
          uid: docRef.id,
          ...User.baseRecord,
          addresses: [userAddress],
          providers: [provider],
          activeWallets: [
            { priority: 0, address: userAddress, provider: provider },
          ],
        };
      });

    return newUser;
  },
  updateProfile: async (input, docId) => {
    await app
      .firestore()
      .collection(User.collection)
      .doc(docId)
      .update({ ...input })
      .catch((e) => {
        console.error('Update failed.');
      });

    const user = await app
      .firestore()
      .collection(User.collection)
      .doc(docId)
      .get()
      .then((doc) => {
        return { ...doc.data(), uid: doc.id };
      });

    return user;
  },
  findByUsername: async (username) => {
    const user = await app
      .firestore()
      .collection(User.collection)
      .where('username', '==', username)
      .get()
      .then((results) => {
        if (results.docs.length === 0) {
          return null;
        }
        const doc = results.docs[0];

        return { uid: doc.id, ...doc.data() };
      });

    return user ?? false;
  },
  usernameTaken: async (username) => {
    const user = await User.findByUsername(username);

    return user ? true : false;
  },
};

export default User;
