import React, { useState } from 'react';
import AppLayout from '../../../layouts/AppLayout';
import Content from './content';
import useUser from '../../../hooks/useUser';
import Preview from './Preview';

export default function Home() {
  const { user } = useUser();
  const [refresh, setRefresh] = useState(0);
  const onUpdate = () => {
    setRefresh(refresh + 1);
  };

  if (!user) {
    return null;
  }

  return (
    <AppLayout
      user={user}
      content={<Content onUpdate={onUpdate} />}
      preview={<Preview refresh={refresh} />}
    />
  );
}
