import defaultImg from '../media/themes/default.jpg';
import darkModeImg from '../media/themes/darkMode.jpg';
import evergreenImg from '../media/themes/evergreen.jpg';
import cottonCandyImg from '../media/themes/cottonCandy.jpg';
import transparentDarkImg from '../media/themes/transparentDark.png';
import transparentBlueImg from '../media/themes/transparentBlue.png';
// import { isMobile } from 'react-device-detect'

import goopBG from '../media/themes/support/goop.svg';
import goopGif from '../media/themes/support/goop.gif';
import bladeRunnerBG from '../media/themes/support/bladeRunner.svg';
import bladeRunnerGif from '../media/themes/support/bladeRunner.gif';

const THEMES = {
  custom: {
    name: 'Custom',
    backgroundClasses: '',
    backgroundStyles: {},
    nameStyles: {
      color: '#FFFFFF',
      fontWeight: 600,
    },
    bioStyles: {
      color: '#FFFFFF',
      fontWeight: 400,
    },
    buttonStyles: {},
    buttonClasses: `
      border border-gray-100 rounded-lg
      text-white bg-transparent 
      hover:bg-gray-100 hover:text-gray-800
    `,
    paymentButtonClasses: `
      border border-gray-300 
      text-gray-500 
      hover:border-gray-900
      hover:bg-gray-900 
      hover:text-white`,
    paymentButtonStyles: {},
    preview: null,
  },
  default: {
    name: 'BlockLink',
    backgroundClasses: '',
    backgroundStyles: {
      backgroundColor: '#FFF',
    },
    nameStyles: {
      color: '#000',
      fontWeight: 600,
    },
    bioStyles: {
      color: '#000',
      fontWeight: 400,
    },
    buttonStyles: {},
    buttonClasses: `
      border border-purple-500 rounded-lg
      text-white bg-purple-500 
      hover:bg-transparent hover:text-purple-500
    `,
    paymentButtonClasses: `
      border border-gray-300 
      text-gray-500 
      hover:border-purple-500
      hover:bg-purple-500 
      hover:text-white`,
    paymentButtonStyles: {},
    preview: defaultImg,
  },
  darkMode: {
    name: 'Dark Mode',
    backgroundClasses: '',
    backgroundStyles: {
      backgroundColor: '#212121',
    },
    nameStyles: {
      color: '#e0e0e0',
      fontWeight: 600,
    },
    bioStyles: {
      color: '#e0e0e0',
      fontWeight: 400,
    },
    buttonStyles: {},
    buttonClasses: `
      border border-gray-100 rounded-lg
      text-white bg-transparent 
      hover:bg-gray-100 hover:text-gray-800
    `,
    paymentButtonClasses: `
      border border-gray-300 
      text-gray-500 
      hover:border-gray-900
      hover:bg-gray-900 
      hover:text-white`,
    paymentButtonStyles: {},
    preview: darkModeImg,
  },
  evergreen: {
    name: 'Evergreen',
    backgroundClasses: '',
    backgroundStyles: {
      backgroundColor: '#ecfffa',
    },
    nameStyles: {
      color: '#212121',
      fontWeight: 600,
    },
    bioStyles: {
      color: '#212121',
      fontWeight: 400,
    },
    buttonStyles: {},
    buttonClasses: `
      border border-green-700
      bg-green-700
      text-white rounded-lg
      hover:bg-transparent
      hover:text-green-700
    `,
    paymentButtonClasses: `
      border border-green-300
      text-gray-500 
      hover:border-green-700
      hover:bg-green-700 
      hover:text-white`,
    paymentButtonStyles: {},
    preview: evergreenImg,
  },
  cottonCandy: {
    name: 'Cotton Candy',
    backgroundClasses:
      'bg-gradient-to-b from-pink-300 via-purple-300 to-indigo-400',
    backgroundStyles: {},
    nameStyles: {
      color: '#FFF',
      fontWeight: 600,
    },
    bioStyles: {
      color: '#FFF',
      fontWeight: 400,
    },
    buttonStyles: {},
    buttonClasses: `
      transition-all duration-500 
      bg-gradient-to-t to-indigo-400 via-indigo400 from-pink-300 bg-size-200 bg-pos-0 hover:bg-pos-100
      text-white 
      rounded-lg
    `,
    paymentButtonClasses: `
      border border-indigo-400
      text-gray-500 
      hover:border-indigo-400
      hover:bg-indigo-400 
      hover:text-white`,
    paymentButtonStyles: {},
    preview: cottonCandyImg,
  },
  goop: {
    name: 'Goop',
    backgroundClasses: '',
    backgroundStyles: {
      background: `url(${goopBG}) center center`,
      backgroundSize: 'auto 100%',
      backgroundColor: '#FFF',
    },
    nameStyles: {
      color: '#e0e0e0',
      fontWeight: 600,
    },
    bioStyles: {
      color: '#e0e0e0',
      fontWeight: 400,
    },
    buttonStyles: {},
    buttonClasses: `
      border border-transparent rounded-lg
      text-white bg-blue-500 
      hover:bg-transparent hover:border-white hover:text-white
    `,
    paymentButtonClasses: `
      border border-gray-300 
      text-gray-500 
      hover:border-blue-500
      hover:bg-blue-500 
      hover:text-white`,
    paymentButtonStyles: {},
    preview: transparentBlueImg,
    previewStyles: {
      backgroundImage: `url(${goopGif})`,
      backgroundSize: 'cover',
    },
  },
  bladeRunner: {
    name: 'Blade Runner',
    backgroundClasses: '',
    backgroundStyles: {
      backgroundImage: `url(${bladeRunnerBG})`,
      backgroundPosition: 'center center',
      backgroundSize: '200% auto',
      backgroundColor: '#FFF',
    },
    nameStyles: {
      color: '#FFF',
      fontWeight: 600,
    },
    bioStyles: {
      color: '#FFF',
      fontWeight: 400,
    },
    buttonStyles: {},
    buttonClasses: `
      border border-transparent rounded-lg
      text-white bg-black 
      hover:bg-transparent hover:border-white hover:text-white
    `,
    paymentButtonClasses: `
      border border-gray-300 
      text-gray-500 
      hover:border-blue-500
      hover:bg-blue-500 
      hover:text-white`,
    paymentButtonStyles: {},
    preview: transparentDarkImg,
    previewStyles: {
      backgroundImage: `url(${bladeRunnerGif})`,
      backgroundSize: 'cover',
    },
  },
};

export default THEMES;
