import React from 'react';
import WelcomeImage from '../../media/welcome.png';
import { isMobile } from 'react-device-detect';

export default function WelcomeLayout({ children }) {
  return (
    <div className="grid grid-cols-6 gap-0 w-screen h-screen">
      <div className="bg-white col-span-6 md:col-span-4">{children}</div>
      {!isMobile && (
        <div className="col-span-2">
          <div
            style={{
              height: '100vh',
              position: 'sticky',
              top: 0,
              backgroundImage: `url(${WelcomeImage})`,
              backgroundSize: '200% 100%',
              backgroundPositionY: -1,
              backgroundPositionX: '48%',
            }}
          />
        </div>
      )}
    </div>
  );
}
