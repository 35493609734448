import React from 'react';
import paths from '../../../paths';
import examplePhone from '../../../media/example_screen.svg';

export default function CTA({ user, handleOpen }) {
  return (
    <div className="flex relative items-center">
      <div className="container mx-auto px-6 flex flex-col justify-between items-center relative py-4">
        <div className="flex flex-col">
          <h1 className="text-3xl md:text-5xl my-6 text-center font-base text-gray-100">
            You Across Blockchains
          </h1>
          <p className="text-lg md:text-xl text-center text-gray-100">
            Connect with everyone in the crypto-universe with just one link.
          </p>
          <ProductHunt />
          <ConnectOrManage user={user} handleOpen={handleOpen} />
        </div>

        <div className="mt-10 w-full md:w-2/6">
          <div className="flex w-full justify-center">
            <img alt="phone example" src={examplePhone} className="w-3/4" />
          </div>
        </div>
      </div>
    </div>
  );
}

const ProductHunt = () => {
  return (
    <div className='w-full flex justify-center mt-4'>
      <a
        rel='nofollow noreferrer'
        href="https://www.producthunt.com/posts/blocklink?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-blocklink"
        target="_blank">
        <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=313809&theme=light"
          alt="BlockLink - Linktree for all your crypto wallets and then some | Product Hunt"
          style={{ width: 250, height: 54 }}
          width="250" height="54" />
      </a>
    </div>
  )
}

const ConnectOrManage = ({ user, handleOpen }) => {
  return (
    <div className="flex flex-col items-center justify-center mt-2">
      {user ? (
        <a
          href={user.username ? paths.user.manage.home() : paths.user.welcome()}
          className="
            py-4 my-2 text-2xl px-4 mt-6 text-white font-semibold rounded-lg shadow-md 
            transition-all duration-500 bg-gradient-to-tl from-purple-800 via-purple-600 
            to-purple-400 bg-size-200 bg-pos-0 hover:bg-pos-100">
          Manage Your BlockLink
        </a>
      ) : (
        <>
          <button
            onClick={handleOpen}
            className="
              py-4 my-2 text-2xl px-4 mt-6 text-white font-semibold rounded-lg shadow-md 
              transition-all duration-500 bg-gradient-to-tl from-purple-800 via-purple-600 
              to-purple-400 bg-size-200 bg-pos-0 hover:bg-pos-100">
            Connect with Web3
          </button>
          <button onClick={handleOpen} className="text-xs text-gray-100">
            already have an account? <u>reconnect with wallet.</u>
          </button>
        </>
      )}
    </div>
  );
};
