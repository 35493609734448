export function validateUsername(username) {
  return username.replace(/[^a-zA-Z0-9-_z]/g, '').replace(/\s+/g, '_');
}

export function formatAddress(address) {
  return `${address.substr(0, 5)}...${address.substr(
    address.length - 5,
    address.length,
  )}`;
}

export function formatAddressLong(address) {
  return `${address.substr(0, 8)}...${address.substr(
    address.length - 8,
    address.length,
  )}`;
}
