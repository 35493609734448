import React, { useState } from 'react';
import { DollarSign, Key, Layout, Share2, Image } from 'react-feather';

import ConnectWallets from '../../../media/marketing/connect_wallets.png';
import GetPaid from '../../../media/marketing/get_paid.png';
import SocialLinks from '../../../media/marketing/social_links.png';
import Themes from '../../../media/marketing/themes.png';

const FEATURES = {
  connectWallets: {
    boldText: 'Supports Multiple Wallets',
    bodyText:
      'Authenticate multiple wallets that can then appear on your profile. They also double as alternative logins!',
    imgSrc: ConnectWallets,
    icon: <Key />,
  },
  getPaid: {
    boldText: 'Get Paid',
    bodyText:
      'We currently support ETH, STX, and SOL - with more to come! So you can easily get paid to any address. No hefty gas fee to manage your ENS.',
    imgSrc: GetPaid,
    icon: <DollarSign />,
  },
  socialLinks: {
    boldText: 'Social Links',
    bodyText:
      'In addition to your wallets, you can link any and all relevant links people may want to see - like your latest NFT project or Rariable profile.',
    imgSrc: SocialLinks,
    icon: <Share2 />,
  },
  themes: {
    boldText: 'Theming',
    bodyText:
      'You are unique and we want that to extend to your profile too! We have some fun basic backgrounds and themes with the post-beta BlockLink having the ability to create your own!',
    imgSrc: Themes,
    icon: <Layout />,
  },
  nftGallery: {
    boldText: 'Cross-Chain NFT Gallery (coming soon)',
    bodyText:
      'You are an esteemed collector and artist, but across chains. No worries, BlockLink allows you to showcase NFTs across chain with any connected wallets.',
    imgSrc: Themes,
    icon: <Image />,
  },
};

export default function FeatureList() {
  const [focusedFeature, setFocusedFeature] = useState('connectWallets');
  const handleAction = (key) => {
    setFocusedFeature(key);
  };

  return (
    <div className="flex-col relative items-center bg-white">
      <div className="container mx-auto bg-white px-6 flex-col justify-between items-center relative py-4">
        <div className="relative bg-white dark:bg-gray-800 p-4">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
            <div className="lg:col-start-2 md:pl-20">
              <h4 className="text-2xl leading-8 font-extrabold text-gray-900 dark:text-white tracking-tight sm:leading-9">
                What can your BlockLink do?
              </h4>
              <ul className="mt-10">
                {Object.keys(FEATURES).map((key) => {
                  const feature = FEATURES[key];
                  return (
                    <li
                      key={key}
                      className="my-6 cursor-pointer"
                      onClick={() => handleAction(key)}
                      onMouseEnter={() => handleAction(key)}>
                      <div className="flex items-center px-1">
                        <div className="flex-shrink-0">
                          <div
                            className={`flex items-center justify-center h-12 w-12 rounded-md ${
                              focusedFeature === key
                                ? 'bg-purple-500 text-white'
                                : 'bg-gray-100 text-purple-500'
                            }`}>
                            {feature.icon}
                          </div>
                        </div>
                        <div className="ml-4">
                          <h5
                            className={`text-lg leading-6 ${
                              focusedFeature === key
                                ? 'text-purple-500'
                                : 'text-gray-900'
                            } dark:text-white font-bold`}>
                            {feature.boldText}
                          </h5>
                          <p className="mt-2 text-base leading-6 text-gray-500 dark:text-gray-300">
                            {feature.bodyText}
                          </p>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="mt-10 -mx-4 md:-mx-12 relative lg:mt-0 lg:col-start-1">
              <img
                src={FEATURES[focusedFeature].imgSrc}
                alt={focusedFeature}
                className=" w-1/2 relative mx-auto shadow-lg rounded"
                style={{
                  maxHeight: 500,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
