import React, { useContext, useEffect, useState } from 'react';
import WelcomeLayout from '../../layouts/WelcomeLayout';
import Jdenticon from 'react-jdenticon';
import useUser from '../../hooks/useUser';
import useSubmitUserForm from './useSubmitUserForm';
import User from '../../models/user';
import paths from '../../paths';
import Logo from '../../media/logo.svg';
import BGImg from '../../media/bg.png';
import { AuthContext } from '../../AuthContext';
import { Loader } from 'react-feather';
import { isMobile } from 'react-device-detect';

export default function Welcome() {
  const context = useContext(AuthContext);
  const { user, provider } = useUser();
  const [submitting, setSubmitting] = useState(false);
  const {
    uploadRef,
    uploading,
    input,
    inputError,
    handleInputUpdate,
    usernameAvailabilityCheck,
    handleFileUpload,
    isValid,
  } = useSubmitUserForm(user);
  const handleSubmit = async () => {
    setSubmitting(true);
    const updatedUser = await User.updateProfile(input, user.uid);
    if (updatedUser) {
      context.actions.updateUser(updatedUser, provider);
      window.location.replace(paths.user.manage.home());
      return;
    }

    setSubmitting(false);
  };

  // If the user has already filled this out -> redirect them because
  // they should not be able to land on the screen after their first login.
  useEffect(() => {
    user.username && window.location.replace(paths.user.manage.home());
  }, [user]);

  return (
    <WelcomeLayout>
      <header
        className="h-20 flex items-center z-30 w-full"
        style={{
          backgroundImage: isMobile ? `url(${BGImg})` : null,
          backgroundSize: 'cover',
        }}>
        <div className="container mx-auto px-6 flex items-center justify-between">
          <div className="dark:text-white flex items-baseline">
            <img
              alt="BlockLink"
              src={Logo}
              className="h-6 filter invert md:invert-0"
            />
          </div>
        </div>
      </header>
      <div className="container mx-6 md:mx-auto w-5/6 md:w-1/2 flex-col space-y-8 mt-18 pt-10 md:pt-4 mb-10">
        <div className="flex">
          <div className="flex-col mx-auto space-y-2">
            <h1 className="text-2xl md:text-3xl font-regular text-gray-800">
              First, let's get you all set up
            </h1>
            <p className="text-gray-500">
              Once you are done here you can fully customize the look and feel
              of your BlockLink.
              <br />
              You can also start to connect the rest of your wallet addresses.
            </p>
          </div>
        </div>

        {/* User name Input */}
        <div className="flex w-full md:w-1/2 mx-auto">
          <div className="flex-col space-y-1">
            <div>
              <h1 className="text-lg font-regular text-gray-800">
                What do you want your username to be?<sup>*</sup>
              </h1>
              <p className="text-xs text-gray-500">
                must be at least 2 characters, emojis allowed.
              </p>
              <p className="text-xs text-red-500">{inputError.username}</p>
            </div>

            <div className="flex ">
              <span className="rounded-l-md inline-flex items-center pl-3 border-t bg-white border-l border-b border-gray-300 text-gray-400 shadow-sm text-base">
                http://blockl.ink/@
              </span>
              <input
                type="text"
                className="font-semibold rounded-r-lg flex-1 appearance-none border-t border-b border-r border-gray-300 w-full py-2 pr-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                placeholder="satoshi"
                value={input.username}
                onBlur={usernameAvailabilityCheck}
                onChange={({ target: { value } }) =>
                  handleInputUpdate('username', value)
                }
              />
            </div>
          </div>
        </div>

        {/* User Email Input */}
        <div className="flex w-full md:w-1/2 mx-auto">
          <div className="flex-col space-y-1">
            <div>
              <h1 className="text-lg font-regular text-gray-800">
                You've got <i>relevant</i> mail!
              </h1>
              <p className="text-xs text-gray-500">
                Optional. This is only so we can contact you about launching and
                updates. <br />
                Never promotional garbage - even our promotional garbage.
              </p>
              {inputError.email && (
                <p className="text-xs text-red-500">{inputError.email}</p>
              )}
            </div>
            <input
              type="email"
              className="font-semibold rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
              placeholder="you@blockchain.com"
              value={input.email}
              onChange={({ target: { value } }) =>
                handleInputUpdate('email', value)
              }
            />
          </div>
        </div>

        {/* User DisplayName Input */}
        <div className="flex w-full md:w-1/2 mx-auto">
          <div className="flex-col space-y-1">
            <div>
              <h1 className="text-lg font-regular text-gray-800">
                What name do you go by?
              </h1>
              <p className="text-xs text-gray-500">
                Optional, will default to username.
              </p>
              {inputError.displayName && (
                <p className="text-xs text-red-500">{inputError.displayName}</p>
              )}
            </div>
            <input
              type="text"
              className="font-semibold rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
              placeholder="Satoshi Nakamoto"
              maxLength={40}
              value={input.displayName}
              onChange={({ target: { value } }) =>
                handleInputUpdate('displayName', value)
              }
            />
          </div>
        </div>

        {/* User Bio Input */}
        <div className="flex w-full md:w-1/2 mx-auto">
          <div className="flex-col space-y-1">
            <div>
              <h1 className="text-lg font-regular text-gray-800">
                Tell everyone a bit about yourself!
              </h1>
              <p className="text-xs text-gray-500">
                Optional. Think of it as a personal tagline. 100 character
                limit.
              </p>
              {inputError.bio && (
                <p className="text-xs text-red-500">{inputError.bio}</p>
              )}
            </div>
            <input
              type="text"
              className="font-semibold rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
              placeholder="Working on a Web3 project..."
              value={input.bio}
              maxLength={100}
              onChange={({ target: { value } }) =>
                handleInputUpdate('bio', value)
              }
            />
          </div>
        </div>

        {/* User Avi Input */}
        <div className="flex w-full md:w-1/2 mx-auto">
          <div className="flex-col space-y-1">
            <div>
              <h1 className="text-lg font-regular text-gray-800">
                Profile Avatar
              </h1>
              <p className="text-xs text-gray-500">
                Optional. Feel free to use an NFT ❤️. Autogenerated otherwise.
              </p>
              {inputError.profileImage && (
                <p className="text-xs text-red-500">
                  {inputError.profileImage}
                </p>
              )}
            </div>
            <div className="flex space-x-4 pb-2">
              <button
                className="text-blue-500 underline text-sm"
                onClick={() => uploadRef.current.click()}>
                upload custom photo
              </button>
              {input.profileImage && (
                <button
                  className="text-gray-400 underline text-sm"
                  onClick={() => handleInputUpdate('profileImage', '')}>
                  reset avatar
                </button>
              )}
            </div>
            <input
              ref={uploadRef}
              type="file"
              onChange={handleFileUpload}
              multiple={false}
              accept="image/png, image/gif, image/jpeg"
              style={{ display: 'none' }}
            />

            {uploading && (
              <div
                className="flex justify-center items-center"
                style={{
                  width: 100,
                  height: 100,
                  clipPath: 'circle(65px at center)',
                }}>
                <Loader className="animate-spin" size={40} color="#9CA3AF" />
              </div>
            )}

            {!uploading && (
              <>
                {input.profileImage ? (
                  <img
                    alt="Your profile avatar"
                    src={input.profileImage}
                    style={{
                      width: 160,
                      height: 160,
                    }}
                    className="rounded-full"
                  />
                ) : (
                  <>
                    <div
                      className="jdenticon-continer"
                      style={{
                        width: 160,
                        height: 160,
                        clipPath: 'circle(65px at center)',
                      }}>
                      <Jdenticon
                        size="160"
                        value={
                          input.username ||
                          (Math.random() + 1).toString(36).substring(7)
                        }
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>

        <div className="flex w-full md:w-1/2 mx-auto">
          {isValid() ? (
            <>
              {submitting ? (
                <button
                  disabled={true}
                  className="rounded-full w-full bg-gray-400 p-2 text-white font-semibold cursor-wait">
                  Creating Your BlockLink...
                </button>
              ) : (
                <button
                  onClick={handleSubmit}
                  className="rounded-full w-full bg-indigo-500 p-2 text-white font-semibold">
                  Create BlockLink
                </button>
              )}
            </>
          ) : (
            <button className="rounded-full w-full bg-gray-400 p-2 text-white font-semibold cursor-not-allowed">
              Complete form to continue
            </button>
          )}
        </div>
      </div>
    </WelcomeLayout>
  );
}
