import React from 'react';
import LinkItem from './LinkItem';
import { SortableContainer } from 'react-sortable-hoc';

const LinkList = SortableContainer(({ links, handleUpdate, handleDelete }) => {
  return (
    <ul className="flex flex-col mt-4 w-full space-y-2">
      {links.map((link) => (
        <LinkItem
          key={link.uid}
          index={link.priority}
          dbLink={link}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
        />
      ))}
    </ul>
  );
});

export default LinkList;
