import React from 'react';
import MarketingHeader from './header';
import BG from '../../media/bg.png';
import useChat from '../../hooks/useChat';

export default function MarketingLayout({ children }) {
  useChat();

  return (
    <>
      <main
        className="font-mono bg-white relative overflow-hidden h-screen z-0 overflow-scroll"
        style={{
          backgroundImage: `url(${BG})`,
          backgroundSize: '110% 110%',
          backgroundPosition: '-10px -10px',
        }}>
        <MarketingHeader />
        {children}

        <footer className="bg-gray-900 w-full py-8">
          <div className="max-w-screen-xl mx-auto px-4">
            <ul className="max-w-screen-md mx-auto text-lg font-light flex flex-wrap justify-center">
              <li className="my-2">
                <a
                  rel="nofollow noreferrer"
                  target="_blank"
                  className="text-sm text-gray-400 hover:text-gray-200 dark:text-gray-300 dark:hover:text-white transition-colors duration-200"
                  href="https://twitter.com/@tcarambat">
                  Built with Web3 by @tcarambat
                </a>
              </li>
            </ul>
          </div>
        </footer>
      </main>
    </>
  );
}
