import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import paths from '../../paths';

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const context = useContext(AuthContext);
  const user = context?.store?.user;

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        !!user ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to={paths.root()} />
        )
      }
    />
  );
};

export default PrivateRoute;
