import React, { useState } from 'react';
import PROVIDERS, { CURRENCIES } from '../../../../utils/crypto/providers';
import { CheckCircle, Loader, X } from 'react-feather';
import { formatAddressLong } from '../../../../utils/user';
import ETHLogo from '../../../../media/eth-logo.svg';
import STXLogo from '../../../../media/stx-logo.svg';
import SOLLogo from '../../../../media/sol-logo.svg';
import MetaMaskLogo from '../../../../media/metamask-icon.svg';
import StacksLogo from '../../../../media/stx-circle.svg';
import PhantomLogo from '../../../../media/phantom-icon.svg';

const LOGOS = {
  ETH: ETHLogo,
  STX: STXLogo,
  SOL: SOLLogo,
  metamask: MetaMaskLogo,
  stacks: StacksLogo,
  phantom: PhantomLogo,
};

export default function PaymentModal({ wallet, handleClose }) {
  const { address, provider } = wallet;
  const currency = CURRENCIES[provider];
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState(null);
  const handlePayment = async () => {
    setLoading(true);
    const result = await PROVIDERS[provider].sendTx(address, value);
    if (!result) {
      setError('transaction could not be completed.');
    } else {
      setCompleted(result);
    }

    setLoading(false);
  };
  const formatValue = (e) => {
    const newValue = parseFloat(e.target.value);
    if (isNaN(newValue)) {
      setValue(0.001);
    } else {
      setValue(newValue);
    }
  };

  return (
    <ModalWrapper handleClose={handleClose}>
      <div className="flex-col justify-center">
        {completed !== false ? (
          <CompletedContent currency={currency} txnHash={completed} />
        ) : (
          <>
            {loading ? (
              <Loader className="animate-spin" size={40} color="#9CA3AF" />
            ) : (
              <div className="flex-col justify-center">
                <p className="text-base md:text-xl font-semibold text-gray-700 text-center">
                  How much {currency.symbol} do you want to send?
                </p>
                {error && (
                  <p className="text-xs text-center text-red-400">{error}</p>
                )}
                <div className="w-3/4 flex-col justify-center items-center space-y-4 mt-4 mx-auto">
                  <div className="flex space-x-2 mx-auto items-end">
                    <input
                      style={{ width: 230 }}
                      value={value}
                      placeholder="0.001"
                      onChange={(e) => setValue(e.target.value)}
                      onBlur={formatValue}
                      className="
                            whitespace-nowrap overflow-scroll
                            text-center text-7xl text-purple-400 border border-purple-100 rounded-lg px-1 outline-none"
                    />
                    <img
                      alt={`${currency.name}`}
                      src={LOGOS[currency.symbol]}
                      width={20}
                      height={20}
                    />
                  </div>

                  <p className="text-gray-500 text-lg">
                    To: {formatAddressLong(address)}
                  </p>
                  <button
                    onClick={handlePayment}
                    className="flex space-x-2 justify-center items-center text-white text-base md:text-xl hover:bg-purple-600 bg-purple-500 p-2 rounded-lg">
                    <img
                      alt={provider}
                      src={LOGOS[provider]}
                      className="h-6 w-6 mr-2"
                    />
                    send with {provider}
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </ModalWrapper>
  );
}

const CompletedContent = ({ currency, txnHash }) => {
  return (
    <div className="w-full flex flex-col items-center">
      <CheckCircle className="text-green-500" size={60} />
      <p className="text-gray-600">Your transaction was sent!</p>
      <a
        href={currency.scannerTxn(txnHash)}
        className="text-blue-500 underline">
        view on {currency.scannerLabel}
      </a>
    </div>
  );
};

const ModalWrapper = ({ handleClose, children }) => {
  return (
    <div
      className="fixed top-0 z-10"
      style={{ width: '100vw', top: 0, height: '100vh', margin: 0 }}>
      <div className="h-screen w-full z-20 inset-0 overflow-y-auto">
        <div
          className="absolute w-full h-full inset-0 bg-gray-500 opacity-75"
          onClick={handleClose}></div>
        <div className="fixed top-0 w-full h-screen flex justify-center items-center">
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"></span>
          <div
            className="inline-block relative overflow-hidden transform transition-all sm:align-middle sm:max-w-lg"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline">
            <div>
              <div className="rounded-lg p-8 bg-white shadow">
                <div className="absolute right-4 top-2">
                  <button
                    onClick={handleClose}
                    className="bg-transparent border border-transparent">
                    <X size={15} />
                  </button>
                </div>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
