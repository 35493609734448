import React, { useEffect, useContext, useState } from 'react';
import BlockLinkIco from '../../../../media/favicon.svg';
import UserLink from '../../../../models/userLink';
import LinkList from './LinkList';
import { arrayMoveImmutable as arrayMove } from 'array-move';
import { Loader } from 'react-feather';
import { AuthContext } from '../../../../AuthContext';

export default function Links({ onUpdate }) {
  const context = useContext(AuthContext);
  const { user } = context.store;
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState([]);
  const handleNewLinkClick = async () => {
    setLoading(true);
    const newLink = await UserLink.createLink(user.uid, links.length);
    newLink && setLinks([...links, newLink]);
    setLoading(false);
    onUpdate();
  };
  const handleDelete = async (linkUid) => {
    const updatedLinkSet = [...links];
    const indexToRemove = updatedLinkSet.findIndex(
      (link) => link.uid === linkUid,
    );
    if (indexToRemove === -1) {
      return false;
    }

    // Remove from updatedLinkSet, delete from firestore and update state
    updatedLinkSet.splice(indexToRemove, 1);
    await UserLink.deleteLink(user.uid, linkUid);
    setLinks(updatedLinkSet);
    onUpdate();
  };
  const handleUpdate = async (linkUid, inputs) => {
    const updatedLinkSet = [...links];
    const indexToReplace = updatedLinkSet.findIndex(
      (link) => link.uid === linkUid,
    );
    if (indexToReplace === -1) {
      return false;
    }

    updatedLinkSet.splice(indexToReplace, 1, { ...inputs });
    await UserLink.updateUserLink(user.uid, linkUid, inputs);
    setLinks(updatedLinkSet);
    onUpdate();
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updateLink = async (userUid, linkUid, input) => {
      await UserLink.updateUserLink(userUid, linkUid, input);
      return Promise.resolve(true);
    };

    const orderedLinks = arrayMove(links, oldIndex, newIndex);
    orderedLinks.map((link, i) => (link.priority = i));
    setLinks(orderedLinks);
    orderedLinks.map((link) => updateLink(user.uid, link.uid, link));
    onUpdate();
  };

  useEffect(() => {
    const fetchLinks = async (userUid) => {
      if (!userUid) {
        return false;
      }
      setLoading(true);
      const userLinks = await UserLink.getUserLinks(userUid);

      // Show links by their priority in numerical order
      if (userLinks.length > 0) {
        userLinks.sort((a, b) => (a.priority > b.priority ? 1 : -1));
      }
      setLinks(userLinks);
      setLoading(false);
    };
    fetchLinks(user.uid);
  }, [user]);

  return (
    <div className="flex flex-col items-center justify-center pt-4 md:pt-0 pb-10 border-b border-gray-200">
      <button
        onClick={handleNewLinkClick}
        disabled={loading}
        className="cursor-pointer p-4 w-full bg-purple-500 hover:bg-purple-600 rounded-xl ">
        {loading ? (
          <p className="flex text-white text-lg font-semibold justify-center items-center">
            <Loader color="#FFF" className="animate-spin" />
          </p>
        ) : (
          <p className="flex text-white text-lg font-semibold justify-center items-center">
            <img
              alt="BlockLink"
              src={BlockLinkIco}
              className="mr-2"
              style={{ height: 20, filter: 'invert(1)' }}
            />
            Add new link
          </p>
        )}
      </button>
      {links.length > 0 && (
        <LinkList
          axis="y"
          transitionDuration={100}
          useDragHandle={true}
          links={links}
          onSortEnd={onSortEnd}
          handleDelete={handleDelete}
          handleUpdate={handleUpdate}
        />
      )}
    </div>
  );
}
